.pageDescription a{
  	text-decoration: none;
  	color: #000;
}

.pageDescription a:hover{
 	text-decoration: none;
  	color: #000;
}

.newsArticleWrapper{
	position: relative;
	float: left;
	width: 80%;
	margin-left: 10%;
	box-sizing: border-box;
	padding: 10px;
	text-align: left;
}

.newsImageFull{
	position: relative;
    width: 80%;
    margin-left: 10%;
    height: auto;
}