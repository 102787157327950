.userFilter{
  position: relative;
  width: 100%;
  height: 20px;
  color: #6d6d6d;
  text-align: left;
  box-sizing: border-box;
  padding: 5px;
  line-height: 20px;
}

.addUserBtn{
  margin-left: 25px;
}

.userFilterUsername {
  position: relative;
  float: left;
  width: 20%;
  bottom: 0px;
  cursor: pointer;
}

.userFilterContactId {
  position: relative;
  float: left;
  width: 15%;
  bottom: 0px;
  cursor: pointer;
  text-align: left;
}

.userFilterLicense {
  position: relative;
  float: left;
  width: 20%;
  bottom: 0px;
  cursor: pointer;
}

.userFilterRole {
  position: relative;
  float: left;
  width: 15%;
  bottom: 0px;
  cursor: pointer;
  text-align: left;
}

.userFilter img {
  position: relative;
  height: 20px;
  line-height: 20px;
  width: auto;
  display: inline-block;
}

.userViewWrapper{
  position: relative;
  float: left;
  width: 100%;
  height: auto;
}

.numLicenses{
  position: relative;
  float: right;
  width: auto;
  height: 100%;
  text-align: right;
  font-style: italic;
  font-size: 12px;
}

.plainBody{
  position: relative;
  float: left;
  margin-top: 10px;
  width: 100%;
  height: auto;
  text-align: left;
  box-sizing: border-box;
  padding: 15px;
}

@media screen and (max-width : 750px) {
  .numLicenses{
    width: 100%;
    float: left;
    text-align: left;
  }

  .userFilter{
    display: none;
  }

  .userFilterLicense{
    display: none;
  }

  .userFilterRole{
    display: none;
  }

  .plainBody{
    padding: 0px;
  }
}