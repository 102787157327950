body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@import url('https://fonts.googleapis.com/css?family=Open+Sans:400italic,600italic,700italic,400,600,700');

.mainWrapper{
  position: relative;
  margin: 0 auto;
  text-align: center;
  width: 100%;
  min-height: 100vh;
  height: auto;
  overflow: hidden;
  z-index: 1;
  /*background-color: #EBEBEB;*/
  background-color: rgb(240, 242, 245);
  left: auto;
  top: auto;
  font-family: 'Open Sans';
}

.mainWindow{
  position: relative;
  /* margin-left: 50px; */
  margin-top: 50px;
  padding: 20px;
  padding-right: 25px;
  padding-left: 75px;
  box-sizing: border-box;
  /*float: left;
  width: 100%;*/
}

.mainWindow a{
  text-decoration: none;
}

.wrapper{
  position: relative;
  margin: 0 auto;
  text-align: center;
  width: 100%;
  min-height: 100vh;
  height: auto;
  overflow: hidden;
  z-index: 1;
  /*background-color: #FFFFFF;*/
  background-color: rgb(240, 242, 245);
  left: auto;
  top: auto;
  font-family: 'Open Sans';
}

.pageTitle {
	position: relative;
	float: left;
	width: 80%;
	margin-left: 10%;
	color: #c62828;
	font-size: 35px;
	text-align: left;
	margin-top: 15px;
	border-bottom: solid;
	border-width: 2px;
	border-color: #e0e4ea;
}

.pageDescription {
    position: relative;
    float: left;
    width: 80%;
    height: auto;
    margin-left: 10%;
    font-size: 15px;
    text-align: left;
    margin-top: 15px;
    margin-bottom: 30px;
}

.createAccount {
    text-align: left;
    background-color: #fff;
    box-shadow: 0 5px 15px 0 rgba(0,0,0,.08), 0 1px 3px 0 rgba(0,0,0,.12);
    margin-bottom: 8px;
    width: 100%;
    min-width: 100%;
    color: #4c535d;
    border-radius: 4px;
    box-sizing: border-box;
    padding: 15px;
    float: left;
}

.footerSpace {
    position: relative;
    float: left;
    height: 200px;
    width: 100%;
}

input.companyInput {
    display: inline-block;
    max-width: 350px;
    width: 100%;
    background-color: #ffffff;
    border-color: #dedede;
    border-style: solid;
}

input.companyInputNull {
    display: inline-block;
    max-width: 350px;
    width: 100%;
    background-color: #f75f57;
    border-color: #dedede;
    border-style: solid;
}

input.projectInputField, textarea.projectInputArea {
    font-size: 15px;
    line-height: 30px;
    display: inline-block;
    height: 30px;
    width: 70%;
}

.pageHeader{
  position: relative;
  width: 100%;
  text-align: left;
  height: 60px;
  border-bottom: solid;
  border-color: #e0e4ea;
  border-width: 2px;
  font-size: 20px;
  line-height: 60px;
  float: left;
}

.featureSelectedCheckbox {
    position: relative;
    top: 8px;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #eee;
    cursor: pointer;
}

.whiteWrapperBody {
  float: left;
  margin-top: 40px;
  height: auto;
  background-color: #fff;
  box-shadow: 0 5px 15px 0 rgba(0,0,0,.08), 0 1px 3px 0 rgba(0,0,0,.12);
  border-radius: 4px;
  box-sizing: border-box;
  padding: 15px;
  margin-bottom: 25px;
  text-align: left;
}

.submitRedBtn {
    height: 50px;
    width: 300px;
    float: left;
    border: none;
    box-sizing: border-box;
    padding: 5px;
    background-color: #c62828;
    display: inline-block;
    color: #fff;
    cursor: pointer;
    font-size: 16px;
    margin: 20px 0 0;
}

@media screen and (max-width : 1050px) {
  .pageHeader{
    height: auto;
    font-size: 18px;
  }

  .submitRedBtn{
    width: 100%;
  }
}