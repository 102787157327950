.callWrapper{
  position: relative;
  float: left;
  width: 100%;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 5px 15px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;
  border-radius: 4px;
  height: 60px;
  margin-top: 10px;
  box-sizing: border-box;
  padding: 5px;
  text-align: left;
  line-height: 50px;
  text-decoration: none;
  color: #000;
  font-size: 18px;
}

.callWrapper:hover{
  box-shadow: 0 3px 6px rgb(0 0 0 / 16%), 0 3px 16px rgb(0 0 0 / 23%);
  cursor: pointer;
}

.callId {
  position: relative;
  float: left;
  width: 10%;
  bottom: 0px;
}

.callMadeBy {
  position: relative;
  float: left;
  width: 22%;
  overflow: hidden;
  text-align: right;
}

.callName {
  position: relative;
  float: left;
  width: 22%;
  overflow: hidden;
  text-align: right;
}

.callDuration {
  position: relative;
  float: left;
  width: 22%;
  text-align: right;
}

.callDate {
  position: relative;
  float: left;
  width: 22%;
  text-align: right;
}

.projectEndDate {
  position: relative;
  float: left;
  width: 15%;
  cursor: pointer;
}

.projectResponses {
  position: relative;
  float: left;
  width: 15%;
  text-align: right;
  cursor: pointer;
}

a{
  text-decoration: none;
}

@media screen and (max-width : 750px) {
  .callWrapper{
    font-size: 9px;
  }
}