.expireDate {
  position: relative;
  float: left;
  width: 16%;
  bottom: 0px;
}

.expireDateUrgent {
  position: relative;
  float: left;
  width: 16%;
  bottom: 0px;
  color: #a83232;
}

.daysRemaining{
  position: relative;
  float: left;
  width: 16%;
  bottom: 0px;
}

.daysRemainingUrgent{
  position: relative;
  float: left;
  width: 16%;
  bottom: 0px;
  color: #a83232;
}

.licenseId{
  position: relative;
  float: left;
  width: 16%;
  bottom: 0px;
}

.licenseStatus{
  position: relative;
  float: left;
  width: 16%;
  bottom: 0px;
}

.licenseUpdate {
  position: relative;
  float: left;
  width: 20%;
  bottom: 0px;
  text-align: right;
}

.licenseRenew {
  position: relative;
  float: left;
  width: 16%;
  bottom: 0px;
  text-align: left;
}

.userInfoWrapper{
  position: relative;
  float: left;
  width: 100%;
  height: auto;
}

.userUpdate button{
  margin-left: 10px;
}

.userRolesWrapper{
  position: relative;
  float: left;
  width: 100%;
  height: auto;
}

.userRoleWrapper{
  position: relative;
  float: left;
  width: 100%;
  line-height: 30px;
}

.contactInfoWrapper{
  line-height: 30px !important;
}

.licenseUpdateBtn{
  margin-right: 10px;
}

@media screen and (max-width : 750px) {
    .licenseId{
      overflow: hidden;
      box-sizing: border-box;
      padding: 5px;
    }

    .licenseRenew{
      overflow: hidden;
      box-sizing: border-box;
      padding: 5px;
      white-space: nowrap;
    }

    .licenseStatus{
      overflow: hidden;
      box-sizing: border-box;
      padding: 5px;
      white-space: nowrap;
    }

    .daysRemainingUrgent{
      overflow: hidden;
      box-sizing: border-box;
      padding: 5px;
      white-space: nowrap;
    }

    .daysRemaining{
      overflow: hidden;
      box-sizing: border-box;
      padding: 5px;
      white-space: nowrap;
    }

    .expireDateUrgent{
      overflow: hidden;
      box-sizing: border-box;
      padding: 5px;
      white-space: nowrap;
    }

    .expireDate{
      overflow: hidden;
      box-sizing: border-box;
      padding: 5px;
      white-space: nowrap;
    }

    .licenseUpdateBtn{
      margin-right: 2px;
      margin-left: 2px;
      width: 100%;
      font-size: 12px;
      text-align: center;
      box-sizing: border-box;
      padding: 5px;
    }
}