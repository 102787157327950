.resetPasswordForm {
    position: relative;
    float: left;
    height: auto;
    width: 60%;
    margin-left: 20%;
}

.resetPasswordForm input {
    position: relative;
    width: 100%;
    height: 30px;
    margin-bottom: 20px;
    font-size: 20px;
}

.resetPasswordFormLabel{
	position: relative;
	width: 100%;
	float: left;
	height: auto;
	text-align: left;
}

.resetPasswordForm button {
    background-color: #c62828;
    border: none;
    color: #ffffff;
    height: 50px;
    position: relative;
    width: 100%;
    margin-bottom: 20px;
    font-size: 20px;
    cursor: pointer;
}