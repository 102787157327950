.createAccount button{
  background-color: #c62828;
  border: none;
  color: #ffffff;
  height: 50px;
  position: relative;
  max-width: 400px;
  width: 100%;
  margin-bottom: 20px;
  font-size: 20px;
  cursor: pointer;
}

.createAccount{
  text-align: left;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 5px 15px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;
  margin-bottom: 8px;
  width: 100%;
  min-width: 100%;
  color: rgb(76, 83, 93);
  border-radius: 4px;
  box-sizing: border-box;
  padding: 15px;
}

.accountTypeInfo img{
  width: 20px;
  height: auto;
}

.accountTypeInfo{
  width: 25px;
  height: auto;
  position: relative;
  display: inline-block;
}

.infoPopup{
  position: absolute;
  left: 20px;
  top: 0px;
  display: none;
  width: 500px;
  height: auto;
  background-color: #2b2b2b;
  color: #fff;
  box-sizing: border-box;
  padding: 10px;
}

.accountTypeInfo:hover .infoPopup{
  display: block;
}

.aliasVerification {
  position: relative;
  height: 25px;
  width: auto;
  display: inline-block;
}

.aliasVerification img{
  position: relative;
  float: left;
  height: 25px;
  width: 25px;
}