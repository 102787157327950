.profileBody {
    float: left;
    margin-top: 40px;
    height: auto;
    background-color: #fff;
    box-shadow: 0 5px 15px 0 rgb(0 0 0 / 8%), 0 1px 3px 0 rgb(0 0 0 / 12%);
    border-radius: 4px;
    box-sizing: border-box;
    padding: 15px;
    margin-bottom: 25px;
    position: relative;
    width: 100%;
    text-align: left;
}

.mainWindow {
    position: relative;
    margin-left: 60px;
    margin-top: 60px;
    padding: 20px;
    box-sizing: border-box;
}

.submitProjectBtn{
    height: 50px;
    width: 300px;
    float: left;
    border: none;
    box-sizing: border-box;
    padding: 5px;
    background-color: #c62828;
    display: inline-block;
    color: #fff;
    cursor: pointer;
    font-size: 16px;
    margin: 20px 0 0;
}