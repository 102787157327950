.createProjectInfoWrapper {
  position: relative;
  float: left;
  width: 100%;
  height: auto;
  margin-top: 10px;
  margin-bottom: 50px;
}

.remoteStream{
	position: relative;
    float: left;
    margin-top: 20px;
    width: 100%;
    height: 640px;
    text-align: left;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.08) 0px 5px 15px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;
    border-radius: 4px;
    box-sizing: border-box;
    margin-bottom: 25px;
    padding: 10px;
}

.sessionInfo{
	position: relative;
	float: left;
	width: 100%;
	height: auto;
	font-size: 18px;
	margin-top: 20px;
	box-sizing: border-box;
	padding: 5px;
}

.sessionInfoTime{
	position: relative;
	float: right;
	width: auto;
	height: auto;
}

.sessionInfoCaller{
	position: relative;
	float: left;
	width: auto;
	height: auto;
}

.remoteStreamWrapper{
	width: 100%;
	height: 100%;
  	margin: 0 auto;
  	background-color: #000;
  	-webkit-transform: rotateX(180deg);
    transform: rotateX(180deg);
}

.channelPropertyWrapper{
	position: relative;
	width: auto;
	height: auto;
	text-align: left;
	float: left;
}

.channelPropertyWrapper img{
	position: absolute;
	width: auto;
	height: 30px;
	cursor: pointer;
}

.channelPropertyWrapper button{
	position: relative;
	margin-right: 5px;
}

.channelContactWrapper{
	position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: rgba( 255, 255, 255, .8 ) 50% 50% no-repeat;
}

.channelContactPopup{
	text-align: left;
    background-color: white;
    box-shadow: rgb(0 0 0 / 8%) 0px 5px 15px 0px, rgb(0 0 0 / 12%) 0px 1px 3px 0px;
    margin-bottom: 8px;
    width: 80%;
    min-width: 80%;
    color: rgb(76, 83, 93);
    border-radius: 4px;
    box-sizing: border-box;
    padding: 15px;
    height: 500px;
    z-index: 2000;
    margin-left: 10%;
    margin-top: 10%;
}

.contactHeaderSearch{
	position: relative;
    height: 40px;
    /* right: 0px; */
    /* top: 5px; */
    width: 350px;
    font-size: 16px;
    float: left;
}

.channelContactHeader{
	position: relative;
	float: left;
	width: 100%;
	height: auto;
}

.channelContactList{
	position: relative;
	float: left;
	width: 100%;
	height: 90%;
	overflow-y: scroll;
	overflow-x: hidden;
	box-sizing: border-box;
	padding: 5px;
}

.contactHeaderSearch input{
	position: relative;
    width: 100%;
    height: 30px;
    line-height: 30px;
    display: inline-block;
    /* margin-top: 10px; */
    border: none;
    box-sizing: border-box;
    padding-left: 5px;
    padding: 0px 8px;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(154, 168, 187);
    border-image: initial;
    border-radius: 4px;
}

.channelContactPopupClose{
	position: relative;
    /* top: 5px; */
    /* right: 5px; */
    width: 25px;
    height: 25px;
    float: right;
    cursor: pointer;
}

.channelContactPopupClose img{
	position: relative;
	width: 100%;
	height: 100%;
}

.leftMenuWrapper{
	position: absolute;
	background-color: #2B2B2B;
	width: 75px;
	height: 620px;
	box-sizing: border-box;
	z-index: 10;
}

.freeDrawMainBtn{
	cursor: pointer;
	width: 100%;
	height: 75px;
	float: left;
}

.freeDrawMainBtn img{
	position: relative;
	width: 100%;
	height: auto;
}

.drawMenuWrapper{
	position: absolute;
	top: 0px;
	left: 75px;
	width: 375px;
	background-color: #2B2B2B;
	height: 75px;
	cursor: pointer;
}

.drawThicknessOption{
	position: relative;
	float: left;
	width: 75px;
	height: 75px;
}

.drawThicknessOption img{
	position: relative;
	float: left;
	width: 100%;
	height: 100%;
}

.shapeMenuWrapper{
	position: absolute;
	top: 75px;
	left: 75px;
	width: 450px;
	background-color: #2B2B2B;
	height: 75px;
	cursor: pointer;
}

.paletteBtn{
	cursor: pointer;
	width: 100%;
	height: 75px;
	float: left;
	background-color: #FF0000;
}

.paletteBtn img{
	position: relative;
	width: 100%;
	height: 100%;
}

.colorMenuWrapper{
	position: absolute;
	left: 75px;
	width: 75px;
	height: 620px;
	background-color: #2B2B2B;
}

.colorRed{
	position: relative;
	float: left;
	width: 65px;
	height: 65px;
	margin-left: 5px;
	margin-top: 5px;
	background-color: #FF0000;
	cursor: pointer;
}

.colorGreen{
	position: relative;
	float: left;
	width: 65px;
	height: 65px;
	margin-left: 5px;
	margin-top: 5px;
	background-color: #00C001;
	cursor: pointer;
}

.colorBlue{
	position: relative;
	float: left;
	width: 65px;
	height: 65px;
	margin-left: 5px;
	margin-top: 5px;
	background-color: #001DC0;
	cursor: pointer;
}

.colorMagenta{
	position: relative;
	float: left;
	width: 65px;
	height: 65px;
	margin-left: 5px;
	margin-top: 5px;
	background-color: #C00092;
	cursor: pointer;
}

.colorYellow{
	position: relative;
	float: left;
	width: 65px;
	height: 65px;
	margin-left: 5px;
	margin-top: 5px;
	background-color: #E8F12C;
	cursor: pointer;
}

.colorOrange{
	position: relative;
	float: left;
	width: 65px;
	height: 65px;
	margin-left: 5px;
	margin-top: 5px;
	background-color: #E08312;
	cursor: pointer;
}

.noteMenuWrapper{
	position: absolute;
    /* float: left; */
    z-index: 10;
    left: 85px;
    width: 100%;
    height: 100%;
    padding-right: 95px;
    padding-bottom: 10px;
}

.waitingForUser{
	position: relative;
	float: left;
    /* float: left; */
    z-index: 10;
    left: 0px;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    text-align: center;
    color: white;
    z-index: 11;
    font-size: 24px;
}

.waitingForUser p {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.center {
  margin: auto;
  width: 50%;
  border: 3px solid green;
  padding: 10px;
  padding: 70px 0;
}

.remoteNote{
	position: relative;
	display: inline-block;
	width: 100%;
	min-height: 90%;
	max-height: 90%;
	padding-right: 75px;
	box-sizing: border-box;
	padding: 10px;
}

.noteTextBtnWrapper{
	position: relative;
    float: left;
    width: 100%;
    height: 53px;
    margin-top: -7px;
    text-align: right;
    box-sizing: border-box;
    padding: 8px;
    background-color: #000;
}

.path {
  fill: none;
  stroke-width: 2px;
  stroke: red;
  stroke-linejoin: round;
  stroke-linecap: round;
}

.drawing {
  width: 100%;
  height: 100%;
  -webkit-transform: rotateX(180deg);
  transform: rotateX(180deg);
  position: absolute;
  z-index: 9;
}