.userCreateClose {
    position: relative;
    width: auto;
    height: 60px;
    font-size: 20px;
    line-height: 60px;
    float: right;
    cursor: pointer;
    z-index: 10;
    text-decoration: none;
}

.userCreateClose a {
    text-decoration: none!important;
    color: #000!important;
}

.whiteWrapperBody{
    width: 100%;
}