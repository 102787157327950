

@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@600;900&display=swap');

.teamWrapper{
  position: relative;
  float: left;
  width: 100%;
  height: auto;
  margin-bottom: 20px;
}

.teamMemberOdd{
  position: relative;
  float: left;
  width: 100%;
  height: auto;
  background-color: #ebebeb;
  box-sizing: border-box;
  padding: 10px;
  margin-bottom: 10px;
}

.teamMemberImage{
  position: relative;
  float: left;
  width: 20%;
  height: auto;
  box-sizing: border-box;
  padding: 5px;
}

.teamMemberImage img{
  position: relative;
  float: left;
  width: 100%;
  height: auto;
}

.teamMemberDesc{
  position: relative;
  float: left;
  width: 70%;
  height: auto;
  box-sizing: border-box;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 15px;
}

.headingNew{
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  --brownish-grey: #707070;
  --charcoal-grey: #3b4550;
  --white: #f3f3f3;
  --row1Height: minmax(33.34vh,33.334fr);
  --row2Height: minmax(66.64vh,66.67fr);
  line-height: 1.42857143;
  font-size: 28px;
  color: #707070;
  font-family: Raleway,sans-serif;
  -webkit-box-direction: normal;
  box-sizing: border-box;
  text-align: center;
  visibility: visible;
  width: 100%;
  height: auto;
  float: left;
  margin-top: 15px;
}

.largeVideoWrapper{
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  --brownish-grey: #707070;
  --charcoal-grey: #3b4550;
  --white: #f3f3f3;
  --row1Height: minmax(33.34vh,33.334fr);
  --row2Height: minmax(66.64vh,66.67fr);
  line-height: 1.42857143;
  font-size: 1.6rem;
  color: #707070;
  font-family: Raleway,sans-serif;
  -webkit-box-direction: normal;
  box-sizing: border-box;
  display: block;
}

.largeVideoPadding{
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    --brownish-grey: #707070;
    --charcoal-grey: #3b4550;
    --white: #f3f3f3;
    --row1Height: minmax(33.34vh,33.334fr);
    --row2Height: minmax(66.64vh,66.67fr);
    line-height: 1.42857143;
    font-size: 1.6rem;
    color: #707070;
    font-family: Raleway,sans-serif;
    -webkit-box-direction: normal;
    box-sizing: border-box;
    padding-bottom: 6rem!important;
    /*padding-top: 9rem!important;*/
    background-color: #f3f3f3;
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    max-height: 720px;
}

.largeVideoSubWrapper{
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    --brownish-grey: #707070;
    --charcoal-grey: #3b4550;
    --white: #f3f3f3;
    --row1Height: minmax(33.34vh,33.334fr);
    --row2Height: minmax(66.64vh,66.67fr);
    line-height: 1.42857143;
    font-size: 1.6rem;
    color: #707070;
    font-family: Raleway,sans-serif;
    -webkit-box-direction: normal;
    box-sizing: border-box;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    width: 1170px;
    perspective: none;
}

.largeVideoSubWrapper2{
      -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    --brownish-grey: #707070;
    --charcoal-grey: #3b4550;
    --white: #f3f3f3;
    --row1Height: minmax(33.34vh,33.334fr);
    --row2Height: minmax(66.64vh,66.67fr);
    line-height: 1.42857143;
    font-size: 1.6rem;
    color: #707070;
    font-family: Raleway,sans-serif;
    -webkit-box-direction: normal;
    box-sizing: border-box;
    min-height: 1px;
    padding-left: 15px;
    float: left;
    width: 100%;
    display: block;
    overflow: hidden;
    height: 100%;
    /*position: absolute;*/
    top: 0;
    right: 0;
    padding-right: 0;
    opacity: 1;
    transform-origin: 50% 50%;
    transform: scale(1);
}

.videoWrapper{
      -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    --brownish-grey: #707070;
    --charcoal-grey: #3b4550;
    --white: #f3f3f3;
    --row1Height: minmax(33.34vh,33.334fr);
    --row2Height: minmax(66.64vh,66.67fr);
    line-height: 1.42857143;
    font-size: 1.6rem;
    color: #707070;
    font-family: Raleway,sans-serif;
    -webkit-box-direction: normal;
    box-sizing: border-box;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
    float: left;
    width: 66.66666667%;
    left: 33.33333333%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    background-position: 100% 0;
}

.videoOverlay{
      -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    --brownish-grey: #707070;
    --charcoal-grey: #3b4550;
    --white: #f3f3f3;
    --row1Height: minmax(33.34vh,33.334fr);
    --row2Height: minmax(66.64vh,66.67fr);
    line-height: 1.42857143;
    font-size: 1.6rem;
    color: #707070;
    font-family: Raleway,sans-serif;
    -webkit-box-direction: normal;
    box-sizing: border-box;
    position: absolute;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg,#f3f3f3 0,#f3f3f3 53%,hsla(0,0%,100%,0));
    z-index: 100;
    display: block;
    transform: translate(-20%);
}

.videoDiv{
      -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    --brownish-grey: #707070;
    --charcoal-grey: #3b4550;
    --white: #f3f3f3;
    --row1Height: minmax(33.34vh,33.334fr);
    --row2Height: minmax(66.64vh,66.67fr);
    line-height: 1.42857143;
    font-size: 1.6rem;
    color: #707070;
    font-family: Raleway,sans-serif;
    -webkit-box-direction: normal;
    box-sizing: border-box;
}

.video-player{
      -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    --brownish-grey: #707070;
    --charcoal-grey: #3b4550;
    --white: #f3f3f3;
    --row1Height: minmax(33.34vh,33.334fr);
    --row2Height: minmax(66.64vh,66.67fr);
    line-height: 1.42857143;
    font-size: 1.6rem;
    color: #707070;
    font-family: Raleway,sans-serif;
    -webkit-box-direction: normal;
    box-sizing: border-box;
    display: inline-block;
    vertical-align: baseline;
    z-index: -999;
    height: 100%;
    transform: translate(0);
}

.video-player video{
  width: 100%;
  height: auto;
}

.mainVideoWrapper{
  width: 100%;
  height: auto;
  max-height: 500px;
  position: relative;
  overflow: hidden;
}

.mainPageExplanationBody{
  position: relative;
  float: left;
  width: 100%;
  height: auto;
  font-size: 18px;
  text-align: center;
  margin-top: 15px;
}

.howitworksExplanation{
  position: relative;
  width: 100%;
  height: auto;
}

.howitworksIndustryWrapper{
  position: relative;
  float: left;
  width: 100%;
  height: auto;
  margin-bottom: 50px;
}

.industryWrapper{
  position: relative;
  float: left;
  width: 31.3%;
  height: auto;
  text-align: left;
  margin-left: 1%;
  margin-right: 1%;
  text-align: left;
  background-color: #fff;
  box-shadow: 0 5px 15px 0 rgba(0,0,0,.08), 0 1px 3px 0 rgba(0,0,0,.12);
  margin-bottom: 8px;
  color: #4c535d;
  border-radius: 4px;
  box-sizing: border-box;
  padding: 10px;
  cursor: pointer;
}

.industryWrapperImg{
  position: relative;
  float: left;
  width: 100%;
  height: auto;
}

.industryWrapperImg img{
  width: 100%;
  height: auto;
}

.heroContentAbout{
  position: absolute;
  top: 20%;
  left: 50px;
  color: #3b4550;
  font-size: 30px;
  font-family: Segoe UI,SegoeUI,"Helvetica Neue",Helvetica,Arial,sans-serif;
  /* background-color: rgb(0, 0, 0, 0.5); */
  box-sizing: border-box;
  padding: 10px;
  max-width: 50%;
  height: auto;
  max-height: 60%;
  text-align: left;
  line-height: 22px;
  z-index: 101;
  /* overflow: hidden;*/
  font-family: Raleway,sans-serif;
}

.heroContentHeader{
  font-size: 35px;
  line-height: 35px;
}

.heroContentBody{
  font-size: 18px;
  line-height: 18px;
  margin-bottom: 15px;
}

.trialButton{
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    --brownish-grey: #707070;
    --charcoal-grey: #3b4550;
    --white: #f3f3f3;
    --row1Height: minmax(33.34vh,33.334fr);
    --row2Height: minmax(66.64vh,66.67fr);
    -webkit-box-direction: normal;
    font-family: Raleway;
    font-stretch: normal;
    font-style: normal;
    text-align: left;
    font-weight: 500;
    line-height: 1.08;
    letter-spacing: normal;
    box-sizing: border-box;
    color: #fff;
    --borderWidth: 2.5px;
    background-color: #eee;
    display: inline-block;
    border-width: var(--borderWidth);
    white-space: normal;
    position: relative;
    -webkit-backface-visibility: hidden;
    transition: box-shadow .4s cubic-bezier(0,0,.23,1),-webkit-box-shadow .4s cubic-bezier(0,0,.23,1);
    text-transform: uppercase;
    font-size: 16px;
    border-radius: 4px;
    box-shadow: 0 0 25.8px 3.2px rgba(0,0,0,.5);
    border: 2.5px solid #a23030;
    background-image: linear-gradient(90deg,#dc3545,#880909);
    outline: none;
    text-decoration: none;
    cursor: pointer;
    margin-left: 2rem;
    padding: 1rem 2rem;
    margin-bottom: 4px;
    margin-top: 4px;
}

.trialButton:hover{
    background-image: linear-gradient(90deg,#880909,#dc3545);
}

@media screen and (max-width : 750px) {
  .teamMemberImage{
    width: 100%;
    height: auto;
  }

  .teamWrapper{
    text-align: center;
  }

  .teamMemberDesc{
    width: 100%;
  }

  .pageDescription ul{
    text-align: left;
  }

  .heroContentAbout{
    width: 100%;
    max-width: none;
    left: 0px;
  }

  .heroContentHeader{
    font-size: 25px;
    line-height: 25px;
    width: 100%;
  }

  .heroContentBody{
    font-size: 12px;
    line-height: 12px;
    margin-bottom: 10px;
    width: 100%;
  }

  .trialButton{
    font-size: 12px;
  }

  .industryWrapper{
    width: 80%;
    margin-left: 10%;
  }
}

@media screen and (max-width : 450px) {
  .heroContentAbout{
    display: none;
  }

  .videoOverlay{
    display: none;
  }
}