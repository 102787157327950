.username {
  position: relative;
  float: left;
  width: 20%;
  bottom: 0px;
}

.contactId{
  position: relative;
  float: left;
  width: 15%;
  bottom: 0px;
  text-align: left;
}

.userLicense {
  position: relative;
  float: left;
  width: 20%;
  bottom: 0px;
}

.userRole {
  position: relative;
  float: left;
  width: 15%;
  bottom: 0px;
  text-align: left;
}

.userUpdate {
  position: relative;
  float: left;
  width: 30%;
  bottom: 0px;
  text-align: right;
}

.userInfoWrapper{
  position: relative;
  float: left;
  width: 100%;
  height: auto;
}

.userUpdate button{
  margin-left: 10px;
}

.userRolesWrapper{
  position: relative;
  float: left;
  width: 100%;
  height: auto;
}

.userRoleWrapper{
  position: relative;
  float: left;
  width: 100%;
  line-height: 30px;
}

.contactInfoWrapper{
  line-height: 30px !important;
}