.topRail{
  position: fixed;
  margin-top: 0px;
  width: 100%;
  height: 50px;
  background-color: #2b2b2b;
  z-index: 100;
  color: #fff;
  top: 0px;
  left: 0px;
}

.logo{
  position: absolute;
  width: 50px;
  height: 50px;
  top: 0px;
  left: 0px;
}

.logo img{
  position: relative;
  width: 40px;
  height: 40px;
  margin-left: 5px;
  margin-top: 5px;
}

.accountInfo {
  position: absolute;
  right: 0px;
  top: 0px;
  width: auto;
  height: 100%;
  border-left-style: solid;
  border-width: 1px;
  border-color: #acacac;
}

.accountInfo img {
  position: relative;
  float: left;
  left: 0px;
  top: 0px;
  width: 30px;
  height: 24px;
  margin-top: 13px;
  margin-right: 13px;
  margin-left: 13px;
}

.usernameHeader {
  position: relative;
  float: left;
  width: auto !important;
  height: 100%;
  line-height: 50px;
}

.accountInfoCarrot {
  position: relative;
  width: 50px;
  float: left;
  height: 100%;
  cursor: pointer;
}

.companyName {
  position: relative;
  margin: 0 auto;
  line-height: 50px;
  width: 50%;
}

@media screen and (max-width : 750px) {
  .companyName{
    display: none;
  }
}