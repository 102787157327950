@import url(https://fonts.googleapis.com/css?family=Open+Sans:400italic,600italic,700italic,400,600,700);
@import url(https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css);
@import url(https://fonts.googleapis.com/css2?family=Raleway:wght@600;900&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.mainWrapper{
  position: relative;
  margin: 0 auto;
  text-align: center;
  width: 100%;
  min-height: 100vh;
  height: auto;
  overflow: hidden;
  z-index: 1;
  /*background-color: #EBEBEB;*/
  background-color: rgb(240, 242, 245);
  left: auto;
  top: auto;
  font-family: 'Open Sans';
}

.mainWindow{
  position: relative;
  /* margin-left: 50px; */
  margin-top: 50px;
  padding: 20px;
  padding-right: 25px;
  padding-left: 75px;
  box-sizing: border-box;
  /*float: left;
  width: 100%;*/
}

.mainWindow a{
  text-decoration: none;
}

.wrapper{
  position: relative;
  margin: 0 auto;
  text-align: center;
  width: 100%;
  min-height: 100vh;
  height: auto;
  overflow: hidden;
  z-index: 1;
  /*background-color: #FFFFFF;*/
  background-color: rgb(240, 242, 245);
  left: auto;
  top: auto;
  font-family: 'Open Sans';
}

.pageTitle {
	position: relative;
	float: left;
	width: 80%;
	margin-left: 10%;
	color: #c62828;
	font-size: 35px;
	text-align: left;
	margin-top: 15px;
	border-bottom: solid;
	border-width: 2px;
	border-color: #e0e4ea;
}

.pageDescription {
    position: relative;
    float: left;
    width: 80%;
    height: auto;
    margin-left: 10%;
    font-size: 15px;
    text-align: left;
    margin-top: 15px;
    margin-bottom: 30px;
}

.createAccount {
    text-align: left;
    background-color: #fff;
    box-shadow: 0 5px 15px 0 rgba(0,0,0,.08), 0 1px 3px 0 rgba(0,0,0,.12);
    margin-bottom: 8px;
    width: 100%;
    min-width: 100%;
    color: #4c535d;
    border-radius: 4px;
    box-sizing: border-box;
    padding: 15px;
    float: left;
}

.footerSpace {
    position: relative;
    float: left;
    height: 200px;
    width: 100%;
}

input.companyInput {
    display: inline-block;
    max-width: 350px;
    width: 100%;
    background-color: #ffffff;
    border-color: #dedede;
    border-style: solid;
}

input.companyInputNull {
    display: inline-block;
    max-width: 350px;
    width: 100%;
    background-color: #f75f57;
    border-color: #dedede;
    border-style: solid;
}

input.projectInputField, textarea.projectInputArea {
    font-size: 15px;
    line-height: 30px;
    display: inline-block;
    height: 30px;
    width: 70%;
}

.pageHeader{
  position: relative;
  width: 100%;
  text-align: left;
  height: 60px;
  border-bottom: solid;
  border-color: #e0e4ea;
  border-width: 2px;
  font-size: 20px;
  line-height: 60px;
  float: left;
}

.featureSelectedCheckbox {
    position: relative;
    top: 8px;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #eee;
    cursor: pointer;
}

.whiteWrapperBody {
  float: left;
  margin-top: 40px;
  height: auto;
  background-color: #fff;
  box-shadow: 0 5px 15px 0 rgba(0,0,0,.08), 0 1px 3px 0 rgba(0,0,0,.12);
  border-radius: 4px;
  box-sizing: border-box;
  padding: 15px;
  margin-bottom: 25px;
  text-align: left;
}

.submitRedBtn {
    height: 50px;
    width: 300px;
    float: left;
    border: none;
    box-sizing: border-box;
    padding: 5px;
    background-color: #c62828;
    display: inline-block;
    color: #fff;
    cursor: pointer;
    font-size: 16px;
    margin: 20px 0 0;
}

@media screen and (max-width : 1050px) {
  .pageHeader{
    height: auto;
    font-size: 18px;
  }

  .submitRedBtn{
    width: 100%;
  }
}
.spinnerWrapper{
	position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: rgba( 255, 255, 255, .8 ) url(https://i.stack.imgur.com/FhHRx.gif) 50% 50% no-repeat;
}

.headerSmall{
  position: relative;
  float: left;
  width: 100%;
  height: 70px;
  text-align: center;
  font-size: 50px;
  color: #ffffff;
  z-index: 102;
  background-color: #2b2b2b;
  line-height: 70px;
  box-shadow: 0px 2px 15px rgb(0 0 0 / 50%);
}

.headerSmall a{
  text-decoration: none;
  color: #fff;
}

.logoSmall{
  position: relative;
  float: left;
  width: auto;
  height: 50px;
  z-index: 10;
  background-repeat: none;
  margin-top: 10px;
  margin-left: 5px;
  line-height: 50px;
  color: #ffffff;
  font-family: 'BebasNeueRegular', sans-serif;
  font-style: normal;
}

.logoSmall img{
  position: relative;
  float: left;
  width: auto;
  height: 100%;
  z-index: 10;
}

.mainMenu{
  position: absolute;
  top: 25px;
  right: 25px;
  width: auto;
  height: auto;
  z-index: 110 !important;
  color: #000000;
}

.menuOption{
  position: relative;
  float: left;
  width: auto;
  height: auto;
  z-index: 2;
  font-size: 18px;
  line-height: 45px;
  padding-left: 20px;
  padding-right: 20px;
  text-decoration: none;
}

.menuRegister{
  position: relative;
  float: left;
  width: 175px;
  height: 40px;
  z-index: 2;
  font-size: 18px;
  line-height: 45px;
  padding-left: 5px;
  padding-right: 5px;
  text-decoration: none;
  cursor: pointer;
}

.menuOption:hover{
  background-color: #454545;
}

.headerActive{
  border-bottom-style: solid;
  border-bottom-width: 3px;
  border-bottom-color: #c62828;
}

.mobileMenu{
  display: none;
}

.mobileMenuWrapper{
  display: none;
}

.subMenu{
  display: none;
  position: absolute;
  width: auto;
  background-color: #454545;
  left: 0px;
  min-width: 250px;
  text-align: left;
  box-sizing: border-box;
  padding: 5px;
  z-index: 102 !important;
}

.subMenuOption{
  position: relative;
  float: left;
  width: 100%;
  height: auto;
  box-sizing: border-box;
  padding: 5px;
}

.subMenuOption:hover{
  background-color: #2b2b2b;
}

.menuOption:hover .subMenu{
  display: block;
}

.registerButton{
  position: relative;
  float: left;
  height: 40px;
  width: 100%;
  box-sizing: border-box;
  /* padding: 5px; */
  background-color: #c62828;
  border-radius: 5px;
}
@media screen and (max-width : 750px) {
  .mainMenu{
    display: none;
  }

  .mobileMenu{
    display: block;
    background-image: url(/images/menu.png);
    position:absolute;
    width:40px;
    height:40px;
    top: 5px;
    right: 5px;
    margin-right: 10px;
    margin-top: 10px;
    z-index:101;
    background-repeat:no-repeat;
    background-size: 100%;
  }

  .mobileMenuWrapper{
    display: block;
    width: 100%;
    margin-top: 70px;
    height: auto;
    background-color: #2b2b2b;
    box-sizing: border-box;
    padding: 10px;
    text-align: center;
    line-height: 40px;
  }

  .mobileMenuOption{
    width: 100%;
    height: auto;
    font-size: 18px;
    border-bottom: 1px solid #595959;
  }
} 
.mainFooterWrapper{
  position: absolute;
  float: left;
  width: 100%;
  height: auto;
  z-index: 4;
  left: 0px;
  text-align: center;
  bottom: 0;
  margin-top: 50px;
  background-color: #2b2b2b;
  color: #ffffff;
  font-family: "Open Sans";
}

.mainFooterWrapper a{
  text-decoration: none;
  color: #fff;
}

.footerSocialWrapper{
  position: relative;
  float: left;
  width: 30%;
  height: auto;
}

.footerSocialImage{
  position: relative;
  width: 100%;
  height: auto;
  box-sizing: border-box;
  text-align: center;
}

.footerSocialImage img{
  position: relative;
  width: auto;
  height: 50px;
  display: inline-block;
}

.footerSocialLogos{
  position: relative;
  float: left;
  width: 100%;
  height: auto;
  box-sizing: border-box;
}

.footerSocialLogos img{
  position: relative;
  padding: 5px;
}

.footerDownloads{
  position: relative;
  float: left;
  width: 100%;
  height: auto;
  box-sizing: border-box;
}

.footerDownloads img{
  position: relative;
  width: auto;
  max-height: 45px;
}

.footerMenu{
  position: relative;
  float: left;
  width: 40%;
  height: inherit;
  text-decoration: none;
  color: #fff;
}

.footerMenu a{
  text-decoration: none;
  color: #fff;
}

.footerEmpty{
  position: relative;
  float: left;
  width: 30%;
  height: auto;
}

.footerEsri{
  position: relative;
  width: 100%;
  height: auto;
  box-sizing: border-box;
  text-align: center;
}

.footerEsri img{
  position: relative;
  width: auto;
  max-width: 100%;
  max-height: 75px;
  display: inline-block;
}

@media screen and (max-width : 750px) {
  .footerSocialWrapper{
    width: 100%;
  }

  .footerMenu{
    width: 100%;
    margin-top: 10px;
  }

  .footerEmpty{
    width: 100%;
    margin-top: 10px;
  }

  .footerSpace{
    height: 400px !important;
  }
}
.mainVideoWrapper{
  width: 100%;
  height: auto;
  position: relative;
  overflow: hidden;
}

.mainVideoWrapper img{
  width: 100%;
  height: auto;
  position: relative;
}

.heroContent{
  position: absolute;
  top: 40%;
  left: 50px;
  color: #fff;
  font-size: 30px;
  font-family: Segoe UI,SegoeUI,"Helvetica Neue",Helvetica,Arial,sans-serif;
  background-color: rgb(0, 0, 0, 0.5);
  box-sizing: border-box;
  padding: 10px;
  max-width: 50%;
  height: auto;
  max-height: 60%;
  text-align: left;
  line-height: 22px;
  z-index: 100;
  overflow: hidden;
}

.learnMoreBtn{
  background-color: #c62828;
  border: none;
  color: #ffffff;
  height: 50px;
  position: relative;
  max-width: 400px;
  width: 100%;
  margin-bottom: 20px;
  font-size: 20px;
  cursor: pointer;
}

.heroButton{
  box-sizing: border-box;
  padding: 10px;
  font-size: 18px;
  background-color: #c62828;
  color: #ffffff;
  border-style: none;
  cursor: pointer;
}

.mainVideoWrapper video{
  object-fit: cover;
  width: 100%;
  height: auto;
}

.homePageExplanation{
  position: relative;
  float: left;
  width: 80%;
  margin-left: 10%;
  height: auto;
  margin-top: 20px;
  margin-bottom: 30px;
  text-align: center;
  font-size: 18px;
}

.mainPageSteps{
  z-index: 2;
  position: relative;
  float: left;
  width: 80%;
  height: auto;
  text-align: center;
  margin-left: 10%;
  margin-top: 25px;
}

.mainPageStep{
  z-index: 2;
  position: relative;
  float: left;
  width: 33.33%;
  height: auto;
  box-sizing: border-box;
  padding: 5px;
  border-left-style: solid;
  border-left-width: 1px;
  border-color: #bbbbbb;
}

.mainPageStep1{
  z-index: 2;
  position: relative;
  float: left;
  width: 33.33%;
  height: auto;
  box-sizing: border-box;
  padding: 5px;
  border-left-style: solid;
  border-left-width: 1px;
  border-color: #bbbbbb;
}

.mainPageStep3{
  z-index: 2;
  position: relative;
  float: left;
  width: 33.33%;
  height: auto;
  box-sizing: border-box;
  padding: 5px;
  border-right-style: solid;
  border-right-width: 1px;
  border-color: #bbbbbb;
  border-left-style: solid;
  border-left-width: 1px;
}

.mainPageNewsWrapper{
  position: relative;
  float: left;
  width: 80%;
  min-height: 80px;
  height: auto;
  margin-left: 10%;
  margin-top: 50px;
  text-align: left;
  font-size: 30px;
  color: #c62828;
}

.mainPageExplanation{
  position: relative;
  float: left;
  width: 80%;
  margin-left: 10%;
  height: auto;
  margin-top: 10px;
  margin-bottom: 50px;
}

.newsClipWrapper{
  position: relative;
  float: left;
  width: 100%;
  height: auto;
  text-align: left;
  text-align: center;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 5px 15px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;
  margin-bottom: 8px;
  color: rgb(76, 83, 93);
  border-radius: 4px;
  box-sizing: border-box;
  padding: 15px;
}

.newsClipWrapper a{
  text-decoration: none;
  color: rgb(76, 83, 93);
}

.footerSpace{
  position: relative;
  float: left;
  height: 200px;
  width: 100%;
}

.newsImage{
  position: relative;
  float: left;
  width: 20%;
  height: auto;
  box-sizing: border-box;
  padding: 5px;
}

.newsImage img{
  position: relative;
  float: left;
  width: 100%;
  height: auto;
}

.newsContent{
  position: relative;
  float: left;
  width: 80%;
  height: auto;
  box-sizing: border-box;
  padding: 5px;
}

.newsTitle{
  position: relative;
  float: left;
  width: 100%;
  font-size: 25px;
  text-align: left;
  font-size: 28px;
}

.newsDate{
  position: relative;
  float: left;
  width: 100%;
  font-size: 25px;
  text-align: left;
  font-size: 14px;
  margin-bottom: 20px;
}

.newsText{
  position: relative;
  float: left;
  width: 100%;
  margin-top: 10px;
  font-size: 16px;
  text-align: left;
}

.homeBody {
  position: relative;
  float: left;
  width: 90%;
  margin-left: 5%;
  height: auto;
  margin-top: 50px;
  margin-bottom: 50px;
}

.homeBodyHeading{
  font-size: 36px;
  line-height: 36px;
  font-family: 'Open Sans';
  font-weight: bolder;
  margin-bottom: 20px;
}

.homeBodyMain{
  position: relative;
  float: left;
  width: 100%;
  height: auto;
}

.homeBodyMainLeft{
  position: relative;
  float: left;
  width: 30%;
  height: auto;
  box-sizing: border-box;
  padding: 15px;
}

.homeBodyMainLeft img{
  position: relative;
  float: left;
  width: 100%;
  height: auto;
}

.homeBodyMainRight{
  position: relative;
  float: left;
  width: 70%;
  height: auto;
  text-align: left;
  box-sizing: border-box;
  padding: 15px;
}

.imageOverlay{
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgb(255,255,255);
  background: radial-gradient(circle, rgba(240,242,245,0) 20%, rgba(240,242,245,1) 100%);
  z-index: 100;
  left: 0px;
  top: 0px;
}

@media screen and (max-width : 750px) {
  .mainPageStep{
    width: 100%;
    border-left: none;
    border-right: none;
    border-bottom: 2px solid #bbbbbb;
    margin-bottom: 10px;
  }

  .mainPageStep1{
    width: 100%;
    border-left: none;
    border-right: none;
    border-bottom: 2px solid #bbbbbb;
    margin-bottom: 10px;
  }

  .mainPageStep3{
    width: 100%;
    border-left: none;
    border-right: none;
    border-bottom: 2px solid #bbbbbb;
    margin-bottom: 10px;
  }

  .mainPageNewsWrapper{
    width: 100%;
    margin-left: 0px;
    box-sizing: border-box;
    padding: 10px;
    text-align: center;
  }

  .newsContent{
    width: 100%;
  }

  .newsTitle{
    text-align: center;
  }

  .newsClipWrapper{
    text-align: center;
    height: auto;
  }

  .newsImage{
    position: relative;
    display: block;
    width: 100%;
    max-height: 220px;
    height: auto;
    margin-top: 15px;
    text-align: center;
  }

  .newsImage img{
    max-width: 100%;
    width: auto;
    height: auto;
    max-height: 220px;
    float: none;
  }

  .newsDate{
    text-align: center;
    margin-bottom: 20px;
  }

  .newsText{
    text-align: center;
  }

  .homeBodyMainLeft{
    width: 100%;
  }

  .homeBodyMainRight{
    width: 100%;
  }
}

@media screen and (max-width : 790px) {
  .heroContent{
    display: none;
  }
}
.userMenuOption{
  position: relative;
  width: 100%;
  height: auto;
  padding: 5px;
  cursor: pointer;
  box-sizing: border-box;
  text-decoration: none !important;
  color: #fff !important;
}

.userMenuOption a:hover{
  text-decoration: none !important;
  color: #fff !important;
}

.userMenuContactId {
  position: relative;
  width: 100%;
  height: auto;
  padding: 5px;
  box-sizing: border-box;
  text-decoration: none !important;
  color: #fff !important;
}
.userMenuWrapper{
  position: absolute;
  width: 100%;
  float: left;
  height: auto;
  background-color: #2b2b2b;
  border-style: solid;
  border-width: 1px;
  border-color: #acacac;
  z-index: 100;
  box-sizing: border-box;
  top: 50px;
  display: block;
}

.userMenuWrapper a:hover{
  text-decoration: none !important;
  color: #fff !important;
}
.topRail{
  position: fixed;
  margin-top: 0px;
  width: 100%;
  height: 50px;
  background-color: #2b2b2b;
  z-index: 100;
  color: #fff;
  top: 0px;
  left: 0px;
}

.logo{
  position: absolute;
  width: 50px;
  height: 50px;
  top: 0px;
  left: 0px;
}

.logo img{
  position: relative;
  width: 40px;
  height: 40px;
  margin-left: 5px;
  margin-top: 5px;
}

.accountInfo {
  position: absolute;
  right: 0px;
  top: 0px;
  width: auto;
  height: 100%;
  border-left-style: solid;
  border-width: 1px;
  border-color: #acacac;
}

.accountInfo img {
  position: relative;
  float: left;
  left: 0px;
  top: 0px;
  width: 30px;
  height: 24px;
  margin-top: 13px;
  margin-right: 13px;
  margin-left: 13px;
}

.usernameHeader {
  position: relative;
  float: left;
  width: auto !important;
  height: 100%;
  line-height: 50px;
}

.accountInfoCarrot {
  position: relative;
  width: 50px;
  float: left;
  height: 100%;
  cursor: pointer;
}

.companyName {
  position: relative;
  margin: 0 auto;
  line-height: 50px;
  width: 50%;
}

@media screen and (max-width : 750px) {
  .companyName{
    display: none;
  }
}
.leftRail{
  position: fixed;
  margin-top: 50px;
  width: 50px;
  height: 100%;
  background-color: #2b2b2b;
  z-index: 100;
  top: 0px;
  left: 0px;
}

.fullLeftRail{
  position: fixed;
  margin-top: 50px;
  width: 250px;
  height: 100%;
  background-color: #2b2b2b;
  z-index: 100;
  top: 0px;
  left: 0px;
}

.leftRailMenu {
	position: absolute;
	float: left;
	width: 100%;
	height: 100%;
	margin-top: 20px;
}

.leftRailMenu img {
    position: relative;
    float: left;
    width: 25px;
    height: auto;
    margin-left: 12px;
}

.menuOptionText {
    position: relative;
    width: 200px;
    float: left;
    margin-left: 10px;
    text-align: left;
    color: #ffffff;
}

.menuOptionText a{
	text-decoration: none;
	color: #ffffff;
}

.menuImageWrapper{
	position: relative;
	width: 100%;
	height: 50px;
}

.active{
	border-left: 2px;
	border-left-color: #c62828;
    border-left-style: solid;
    height: 75px;
    position:relative;
    float:left;
}

.childActiveSmall {
    float: left;
    width: 50px;
    height: 25px;
    margin-top: 20px;
    margin-bottom: 20px;
}

.childActiveFull{
	float: left;
	width: 250px;
	height: 25px;
	margin-top: 20px;
	margin-bottom: 20px;
}

.logoutLeftMenuSmall {
    position: fixed;
    bottom: 20px;
    left: 0px;
    width: 50px;
    height: 25px;
    margin-top: 20px;
    cursor: pointer;
}

.logoutLeftMenuFull {
    position: fixed;
    bottom: 20px;
    left: 0px;
    width: 250px;
    height: 25px;
    margin-top: 20px;
    cursor: pointer;
}

.popover{
  position: absolute;
  top: 270px;
  left: 50px;
  margin: 0px;
  z-index: 1060;
  width: 300px;
  max-width: none !important; 
  font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,Liberation Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: .875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0,0,0,.2);
  border-radius: .3rem;
}

.arrow{
  margin: 0px;
  position: absolute;
  top: 0px;
  transform: translate(0px, 39.2px);
  left: calc(-.5rem - 1px);
  width: .5rem;
  height: 1rem;
  display: block;
}

.popover-header {
  padding: .5rem .75rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(.3rem - 1px);
  border-top-right-radius: calc(.3rem - 1px);
  text-decoration: none;
}

.popover-header a{
  color: #000;
}

.popover a{
  color: #000;
}

.closeBillingPopup{
  position: absolute;
  height: 20px;
  width: 20px;
  right: 5px;
  top: 5px;
  cursor: pointer;
}

.closeBillingPopup img{
  position: absolute;
  width: 100%;
  height: 100%;
}
.createAccount button{
  background-color: #c62828;
  border: none;
  color: #ffffff;
  height: 50px;
  position: relative;
  max-width: 400px;
  width: 100%;
  margin-bottom: 20px;
  font-size: 20px;
  cursor: pointer;
}

.createAccount{
  text-align: left;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 5px 15px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;
  margin-bottom: 8px;
  width: 100%;
  min-width: 100%;
  color: rgb(76, 83, 93);
  border-radius: 4px;
  box-sizing: border-box;
  padding: 15px;
}

.accountTypeInfo img{
  width: 20px;
  height: auto;
}

.accountTypeInfo{
  width: 25px;
  height: auto;
  position: relative;
  display: inline-block;
}

.infoPopup{
  position: absolute;
  left: 20px;
  top: 0px;
  display: none;
  width: 500px;
  height: auto;
  background-color: #2b2b2b;
  color: #fff;
  box-sizing: border-box;
  padding: 10px;
}

.accountTypeInfo:hover .infoPopup{
  display: block;
}

.aliasVerification {
  position: relative;
  height: 25px;
  width: auto;
  display: inline-block;
}

.aliasVerification img{
  position: relative;
  float: left;
  height: 25px;
  width: 25px;
}
.pageTitle{
	position: relative;
    float: left;
    width: 80%;
    margin-left: 10%;
    color: #c62828;
    font-size: 35px;
    text-align: left;
    margin-top: 15px;
    border-bottom-style: solid;
    border-color: rgb(224, 228, 234);
    border-width: 2px;
}

.loginFormWrapper{
	position: relative;
    float: left;
    width: 100%;
    height: auto;
    margin-top: 25px;
    text-align: center;
}

.loginForm{
	position: relative;
	float: left;
	height: auto;
	width: 100%;
}

.loginAuth0{
	position: relative;
	float: left;
	height: auto;
	width: 100%;
	text-align: middle;
}

.loginAuth0 button{
	background-color: #c62828;
    border: none;
    color: #ffffff;
    height: 50px;
    position: relative;
    width: 50%;
    margin-bottom: 20px;
    font-size: 20px;
    cursor: pointer;
}

.loginAuth0 input{
	position: relative;
    width: 50%;
    height: 30px;
    margin-bottom: 20px;
    font-size: 20px;
}

.loginForm input{
	position: relative;
    width: 50%;
    height: 30px;
    margin-bottom: 20px;
    font-size: 20px;
}

.loginForm button{
	background-color: #c62828;
    border: none;
    color: #ffffff;
    height: 50px;
    position: relative;
    width: 50%;
    margin-bottom: 20px;
    font-size: 20px;
    cursor: pointer;
}

@media screen and (max-width : 750px) {
  .loginAuth0 input{
    width: 100%;
  }

  .loginAuth0 button{
    width: 100%;
  }
}
.callWrapper{
  position: relative;
  float: left;
  width: 100%;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 5px 15px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;
  border-radius: 4px;
  height: 60px;
  margin-top: 10px;
  box-sizing: border-box;
  padding: 5px;
  text-align: left;
  line-height: 50px;
  text-decoration: none;
  color: #000;
  font-size: 18px;
}

.callWrapper:hover{
  box-shadow: 0 3px 6px rgb(0 0 0 / 16%), 0 3px 16px rgb(0 0 0 / 23%);
  cursor: pointer;
}

.callId {
  position: relative;
  float: left;
  width: 10%;
  bottom: 0px;
}

.callMadeBy {
  position: relative;
  float: left;
  width: 22%;
  overflow: hidden;
  text-align: right;
}

.callName {
  position: relative;
  float: left;
  width: 22%;
  overflow: hidden;
  text-align: right;
}

.callDuration {
  position: relative;
  float: left;
  width: 22%;
  text-align: right;
}

.callDate {
  position: relative;
  float: left;
  width: 22%;
  text-align: right;
}

.projectEndDate {
  position: relative;
  float: left;
  width: 15%;
  cursor: pointer;
}

.projectResponses {
  position: relative;
  float: left;
  width: 15%;
  text-align: right;
  cursor: pointer;
}

a{
  text-decoration: none;
}

@media screen and (max-width : 750px) {
  .callWrapper{
    font-size: 9px;
  }
}
.projectsHeaderWrapper{
    position: relative;
    width: 100%;
    height: 60px;
    border-bottom-style: solid;
    border-width: 2px;
    border-color: rgb(224, 228, 234) !important;
    font-size: 24px;
}

.projectsHeaderText{
    position: relative;
    float: left;
    width: auto;
    height: 60px;
    line-height: 60px;
    margin-right: 50px;
    border-bottom-style: solid;
    border-width: 2px;
    border-color: #C62828;
}

.projectsHeaderSearch{
    position: absolute;
    height: 40px;
    right: 0px;
    top: 5px;
    width: 350px;
    font-size: 16px;
}

input.projectsHeaderSearch {
    position: relative;
    width: 100%;
    height: 30px;
    line-height: 30px;
    display: inline-block;
    margin-top: 10px;
    border: none;
    box-sizing: border-box;
    padding-left: 5px;
    padding: 0px 8px;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(154, 168, 187);
    border-image: none;
    border-image: initial;
    border-radius: 4px;
}

@media screen and (max-width : 750px) {
  .projectsHeaderSearch{
    width: 100%;
    top: 0px;
    position: relative;
    float: left;
    margin-bottom: 2px;
  }

  .projectsHeaderText{
    width: 100%;
    margin-right: 0px;
    float: left;
    position: relative;
    height: auto;
  }

  .projectsHeaderWrapper{
    font-size: 18px;
    position: relative;
    float: left;
    height: auto;
    width: 100%;
    margin-bottom: 5px;
  }
}
a{
  text-decoration: none;
}

.noProjects{
  position: relative;
  float: left;
  width: 80%;
  margin-left: 10%;
  height: auto;
  color: #000;
  box-sizing: border-box;
  padding: 15px;
  margin-top: 20px;
  box-shadow:  0 0 10px  rgba(0,0,0,0.6);
  -moz-box-shadow: 0 0 10px  rgba(0,0,0,0.6);
  -webkit-box-shadow: 0 0 10px  rgba(0,0,0,0.6);
  -o-box-shadow: 0 0 10px  rgba(0,0,0,0.6);
  border-radius: 15px;
  background-color: #fff3cd;
  border-color: #ffeeba;
}

.projectsFilterWrapper{ 
  position: relative;
  width: 100%;
  height: 20px;
  margin-top: 30px;
  color: #6d6d6d;
  text-align: left;
  box-sizing: border-box;
  padding: 5px;
  line-height: 20px;
  font-size: 20px;
}

.projectsFilterWrapper img{
    position: relative;
    height: 20px;
    line-height: 20px;
    width: auto;
    display: inline-block;
}

.filterId{
  position: relative;
    float: left;
    width: 10%;
    bottom: 0px;
    cursor: pointer;
}

.filterCallMadeBy{
    position: relative;
    float: left;
    width: 22%;
    bottom: 0px;
    text-align: right;
    cursor: pointer;
}

.filterName{
  position: relative;
    float: left;
    width: 22%;
    bottom: 0px;
    text-align: right;
    cursor: pointer;
}

.filterDuration{
  position: relative;
    float: left;
    width: 22%;
    bottom: 0px;
    text-align: right;
    cursor: pointer;
}

.filterDate{
  position: relative;
    float: left;
    width: 22%;
    bottom: 0px;
    text-align: right;
    cursor: pointer;
}

.projectsFilterEnd{
  position: relative;
    float: left;
    width: 15%;
    bottom: 0px;
    cursor: pointer;
}

.projectsFilterResponses{
  position: relative;
    float: left;
    width: 15%;
    bottom: 0px;
    cursor: pointer;
    text-align: right;
}

@media screen and (max-width : 750px) {
  .projectsFilterWrapper{
    font-size: 12px;
  }
}
.username {
  position: relative;
  float: left;
  width: 20%;
  bottom: 0px;
}

.contactId{
  position: relative;
  float: left;
  width: 15%;
  bottom: 0px;
  text-align: left;
}

.userLicense {
  position: relative;
  float: left;
  width: 20%;
  bottom: 0px;
}

.userRole {
  position: relative;
  float: left;
  width: 15%;
  bottom: 0px;
  text-align: left;
}

.userUpdate {
  position: relative;
  float: left;
  width: 30%;
  bottom: 0px;
  text-align: right;
}

.userWrapper {
  position: relative;
  float: left;
  width: 100%;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 5px 15px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;
  border-radius: 4px;
  min-height: 60px;
  height: auto;
  margin-top: 10px;
  box-sizing: border-box;
  padding: 15px;
  text-align: left;
  line-height: 50px;
}

.userInfoWrapper{
  position: relative;
  float: left;
  width: 100%;
  height: auto;
}

.userUpdate button{
  margin-left: 10px;
}

.userRolesWrapper{
  position: relative;
  float: left;
  width: 100%;
  height: auto;
}

.userRoleWrapper{
  position: relative;
  float: left;
  width: 100%;
  line-height: 30px;
}

.contactInfoWrapper{
  line-height: 30px !important;
}

@media screen and (max-width : 750px) {
  .userWrapper{
    font-size: 10px !important;
  }

  .contactId{
    display: none;
  }

  .userLicense{
    display: none;
  }

  .userRole{
    display: none;
  }

  .username{
    width: 50% !important;
  }

  .userUpdate{
    width: 50% !important;
    float: right;
  }
}
.userFilter{
  position: relative;
  width: 100%;
  height: 20px;
  color: #6d6d6d;
  text-align: left;
  box-sizing: border-box;
  padding: 5px;
  line-height: 20px;
}

.addUserBtn{
  margin-left: 25px;
}

.userFilterUsername {
  position: relative;
  float: left;
  width: 20%;
  bottom: 0px;
  cursor: pointer;
}

.userFilterContactId {
  position: relative;
  float: left;
  width: 15%;
  bottom: 0px;
  cursor: pointer;
  text-align: left;
}

.userFilterLicense {
  position: relative;
  float: left;
  width: 20%;
  bottom: 0px;
  cursor: pointer;
}

.userFilterRole {
  position: relative;
  float: left;
  width: 15%;
  bottom: 0px;
  cursor: pointer;
  text-align: left;
}

.userFilter img {
  position: relative;
  height: 20px;
  line-height: 20px;
  width: auto;
  display: inline-block;
}

.userViewWrapper{
  position: relative;
  float: left;
  width: 100%;
  height: auto;
}

.numLicenses{
  position: relative;
  float: right;
  width: auto;
  height: 100%;
  text-align: right;
  font-style: italic;
  font-size: 12px;
}

.plainBody{
  position: relative;
  float: left;
  margin-top: 10px;
  width: 100%;
  height: auto;
  text-align: left;
  box-sizing: border-box;
  padding: 15px;
}

@media screen and (max-width : 750px) {
  .numLicenses{
    width: 100%;
    float: left;
    text-align: left;
  }

  .userFilter{
    display: none;
  }

  .userFilterLicense{
    display: none;
  }

  .userFilterRole{
    display: none;
  }

  .plainBody{
    padding: 0px;
  }
}
.userCreateClose {
    position: relative;
    width: auto;
    height: 60px;
    font-size: 20px;
    line-height: 60px;
    float: right;
    cursor: pointer;
    z-index: 10;
    text-decoration: none;
}

.userCreateClose a {
    text-decoration: none!important;
    color: #000!important;
}

.createProjectInfoWrapper {
  position: relative;
  float: left;
  width: 100%;
  height: auto;
  margin-top: 10px;
  margin-bottom: 50px;
}

.remoteStream{
	position: relative;
    float: left;
    margin-top: 20px;
    width: 100%;
    height: 640px;
    text-align: left;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.08) 0px 5px 15px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;
    border-radius: 4px;
    box-sizing: border-box;
    margin-bottom: 25px;
    padding: 10px;
}

.sessionInfo{
	position: relative;
	float: left;
	width: 100%;
	height: auto;
	font-size: 18px;
	margin-top: 20px;
	box-sizing: border-box;
	padding: 5px;
}

.sessionInfoTime{
	position: relative;
	float: right;
	width: auto;
	height: auto;
}

.sessionInfoCaller{
	position: relative;
	float: left;
	width: auto;
	height: auto;
}

.remoteStreamWrapper{
	width: 100%;
	height: 100%;
  	margin: 0 auto;
  	background-color: #000;
   transform: rotateX(180deg);
}

.channelPropertyWrapper{
	position: relative;
	width: auto;
	height: auto;
	text-align: left;
	float: left;
}

.channelPropertyWrapper img{
	position: absolute;
	width: auto;
	height: 30px;
	cursor: pointer;
}

.channelPropertyWrapper button{
	position: relative;
	margin-right: 5px;
}

.channelContactWrapper{
	position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: rgba( 255, 255, 255, .8 ) 50% 50% no-repeat;
}

.channelContactPopup{
	text-align: left;
    background-color: white;
    box-shadow: rgb(0 0 0 / 8%) 0px 5px 15px 0px, rgb(0 0 0 / 12%) 0px 1px 3px 0px;
    margin-bottom: 8px;
    width: 80%;
    min-width: 80%;
    color: rgb(76, 83, 93);
    border-radius: 4px;
    box-sizing: border-box;
    padding: 15px;
    height: 500px;
    z-index: 2000;
    margin-left: 10%;
    margin-top: 10%;
}

.contactHeaderSearch{
	position: relative;
    height: 40px;
    /* right: 0px; */
    /* top: 5px; */
    width: 350px;
    font-size: 16px;
    float: left;
}

.channelContactHeader{
	position: relative;
	float: left;
	width: 100%;
	height: auto;
}

.channelContactList{
	position: relative;
	float: left;
	width: 100%;
	height: 90%;
	overflow-y: scroll;
	overflow-x: hidden;
	box-sizing: border-box;
	padding: 5px;
}

.contactHeaderSearch input{
	position: relative;
    width: 100%;
    height: 30px;
    line-height: 30px;
    display: inline-block;
    /* margin-top: 10px; */
    border: none;
    box-sizing: border-box;
    padding-left: 5px;
    padding: 0px 8px;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(154, 168, 187);
    border-image: none;
    border-image: initial;
    border-radius: 4px;
}

.channelContactPopupClose{
	position: relative;
    /* top: 5px; */
    /* right: 5px; */
    width: 25px;
    height: 25px;
    float: right;
    cursor: pointer;
}

.channelContactPopupClose img{
	position: relative;
	width: 100%;
	height: 100%;
}

.leftMenuWrapper{
	position: absolute;
	background-color: #2B2B2B;
	width: 75px;
	height: 620px;
	box-sizing: border-box;
	z-index: 10;
}

.freeDrawMainBtn{
	cursor: pointer;
	width: 100%;
	height: 75px;
	float: left;
}

.freeDrawMainBtn img{
	position: relative;
	width: 100%;
	height: auto;
}

.drawMenuWrapper{
	position: absolute;
	top: 0px;
	left: 75px;
	width: 375px;
	background-color: #2B2B2B;
	height: 75px;
	cursor: pointer;
}

.drawThicknessOption{
	position: relative;
	float: left;
	width: 75px;
	height: 75px;
}

.drawThicknessOption img{
	position: relative;
	float: left;
	width: 100%;
	height: 100%;
}

.shapeMenuWrapper{
	position: absolute;
	top: 75px;
	left: 75px;
	width: 450px;
	background-color: #2B2B2B;
	height: 75px;
	cursor: pointer;
}

.paletteBtn{
	cursor: pointer;
	width: 100%;
	height: 75px;
	float: left;
	background-color: #FF0000;
}

.paletteBtn img{
	position: relative;
	width: 100%;
	height: 100%;
}

.colorMenuWrapper{
	position: absolute;
	left: 75px;
	width: 75px;
	height: 620px;
	background-color: #2B2B2B;
}

.colorRed{
	position: relative;
	float: left;
	width: 65px;
	height: 65px;
	margin-left: 5px;
	margin-top: 5px;
	background-color: #FF0000;
	cursor: pointer;
}

.colorGreen{
	position: relative;
	float: left;
	width: 65px;
	height: 65px;
	margin-left: 5px;
	margin-top: 5px;
	background-color: #00C001;
	cursor: pointer;
}

.colorBlue{
	position: relative;
	float: left;
	width: 65px;
	height: 65px;
	margin-left: 5px;
	margin-top: 5px;
	background-color: #001DC0;
	cursor: pointer;
}

.colorMagenta{
	position: relative;
	float: left;
	width: 65px;
	height: 65px;
	margin-left: 5px;
	margin-top: 5px;
	background-color: #C00092;
	cursor: pointer;
}

.colorYellow{
	position: relative;
	float: left;
	width: 65px;
	height: 65px;
	margin-left: 5px;
	margin-top: 5px;
	background-color: #E8F12C;
	cursor: pointer;
}

.colorOrange{
	position: relative;
	float: left;
	width: 65px;
	height: 65px;
	margin-left: 5px;
	margin-top: 5px;
	background-color: #E08312;
	cursor: pointer;
}

.noteMenuWrapper{
	position: absolute;
    /* float: left; */
    z-index: 10;
    left: 85px;
    width: 100%;
    height: 100%;
    padding-right: 95px;
    padding-bottom: 10px;
}

.waitingForUser{
	position: relative;
	float: left;
    /* float: left; */
    z-index: 10;
    left: 0px;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    text-align: center;
    color: white;
    z-index: 11;
    font-size: 24px;
}

.waitingForUser p {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.center {
  margin: auto;
  width: 50%;
  border: 3px solid green;
  padding: 10px;
  padding: 70px 0;
}

.remoteNote{
	position: relative;
	display: inline-block;
	width: 100%;
	min-height: 90%;
	max-height: 90%;
	padding-right: 75px;
	box-sizing: border-box;
	padding: 10px;
}

.noteTextBtnWrapper{
	position: relative;
    float: left;
    width: 100%;
    height: 53px;
    margin-top: -7px;
    text-align: right;
    box-sizing: border-box;
    padding: 8px;
    background-color: #000;
}

.path {
  fill: none;
  stroke-width: 2px;
  stroke: red;
  stroke-linejoin: round;
  stroke-linecap: round;
}

.drawing {
  width: 100%;
  height: 100%;
  transform: rotateX(180deg);
  position: absolute;
  z-index: 9;
}
.analyticsWrapper{
  position: relative;
  width: 100%;
  height: 500px;
  float: left;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 5px 15px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;
  margin-bottom: 8px;
  color: rgb(76, 83, 93);
  border-radius: 4px;
  box-sizing: border-box;
  padding: 15px;
  background-color: white;
  margin-top: 10px;
}

.analyticsFilters{
  position: relative;
  float: left;
  width: 100%;
  height: auto;
  padding: 5px;
  box-sizing: border-box;
}

.analyticsFilters button{
  position: relative;
  float: left;
  margin-left: 5px;
}
.expireDate {
  position: relative;
  float: left;
  width: 16%;
  bottom: 0px;
}

.expireDateUrgent {
  position: relative;
  float: left;
  width: 16%;
  bottom: 0px;
  color: #a83232;
}

.daysRemaining{
  position: relative;
  float: left;
  width: 16%;
  bottom: 0px;
}

.daysRemainingUrgent{
  position: relative;
  float: left;
  width: 16%;
  bottom: 0px;
  color: #a83232;
}

.licenseId{
  position: relative;
  float: left;
  width: 16%;
  bottom: 0px;
}

.licenseStatus{
  position: relative;
  float: left;
  width: 16%;
  bottom: 0px;
}

.licenseUpdate {
  position: relative;
  float: left;
  width: 20%;
  bottom: 0px;
  text-align: right;
}

.licenseRenew {
  position: relative;
  float: left;
  width: 16%;
  bottom: 0px;
  text-align: left;
}

.userInfoWrapper{
  position: relative;
  float: left;
  width: 100%;
  height: auto;
}

.userUpdate button{
  margin-left: 10px;
}

.userRolesWrapper{
  position: relative;
  float: left;
  width: 100%;
  height: auto;
}

.userRoleWrapper{
  position: relative;
  float: left;
  width: 100%;
  line-height: 30px;
}

.contactInfoWrapper{
  line-height: 30px !important;
}

.licenseUpdateBtn{
  margin-right: 10px;
}

@media screen and (max-width : 750px) {
    .licenseId{
      overflow: hidden;
      box-sizing: border-box;
      padding: 5px;
    }

    .licenseRenew{
      overflow: hidden;
      box-sizing: border-box;
      padding: 5px;
      white-space: nowrap;
    }

    .licenseStatus{
      overflow: hidden;
      box-sizing: border-box;
      padding: 5px;
      white-space: nowrap;
    }

    .daysRemainingUrgent{
      overflow: hidden;
      box-sizing: border-box;
      padding: 5px;
      white-space: nowrap;
    }

    .daysRemaining{
      overflow: hidden;
      box-sizing: border-box;
      padding: 5px;
      white-space: nowrap;
    }

    .expireDateUrgent{
      overflow: hidden;
      box-sizing: border-box;
      padding: 5px;
      white-space: nowrap;
    }

    .expireDate{
      overflow: hidden;
      box-sizing: border-box;
      padding: 5px;
      white-space: nowrap;
    }

    .licenseUpdateBtn{
      margin-right: 2px;
      margin-left: 2px;
      width: 100%;
      font-size: 12px;
      text-align: center;
      box-sizing: border-box;
      padding: 5px;
    }
}
.licenseFilter{
  position: relative;
  width: 100%;
  height: 20px;
  color: #6d6d6d;
  text-align: left;
  box-sizing: border-box;
  padding: 5px;
  line-height: 20px;
}

.addUserBtn{
  margin-left: 25px;
}

.licenseFilterExpireDate {
  position: relative;
  float: left;
  width: 16%;
  bottom: 0px;
  cursor: pointer;
}

.licenseFilterStatus {
  position: relative;
  float: left;
  width: 16%;
  bottom: 0px;
  cursor: pointer;
}

.licenseFilterId {
  position: relative;
  float: left;
  width: 16%;
  bottom: 0px;
  cursor: pointer;
}

.licenseFilterDaysRemaining {
  position: relative;
  float: left;
  width: 16%;
  bottom: 0px;
  cursor: pointer;
  text-align: left;
}

.licenseFilterUpdate {
  position: relative;
  float: left;
  width: 20%;
  bottom: 0px;
  cursor: pointer;
  text-align: left;
}

.userFilter img {
  position: relative;
  height: 20px;
  line-height: 20px;
  width: auto;
  display: inline-block;
}

@media screen and (max-width : 750px) {
  .licenseFilter{
    font-size: 12px;
  }
}
.profileBody {
    float: left;
    margin-top: 40px;
    height: auto;
    background-color: #fff;
    box-shadow: 0 5px 15px 0 rgb(0 0 0 / 8%), 0 1px 3px 0 rgb(0 0 0 / 12%);
    border-radius: 4px;
    box-sizing: border-box;
    padding: 15px;
    margin-bottom: 25px;
    position: relative;
    width: 100%;
    text-align: left;
}

.mainWindow {
    position: relative;
    margin-left: 60px;
    margin-top: 60px;
    padding: 20px;
    box-sizing: border-box;
}

.submitProjectBtn{
    height: 50px;
    width: 300px;
    float: left;
    border: none;
    box-sizing: border-box;
    padding: 5px;
    background-color: #c62828;
    display: inline-block;
    color: #fff;
    cursor: pointer;
    font-size: 16px;
    margin: 20px 0 0;
}



.pageDescription{
  position: relative;
  float: left;
  width: 80%;
  height: auto;
  margin-left: 10%;
  font-size: 15px;
  text-align: left;
  margin-top: 15px;
  margin-bottom: 30px;
}

.howitworksExplanation{
  position: relative;
  width: 100%;
  height: auto;
}

.howitworksIndustryWrapper{
  position: relative;
  float: left;
  width: 100%;
  height: auto;
  margin-bottom: 50px;
}



.industryDetailWrapper{
  position: relative;
  float: left;
  width: 100%;
  height: auto;
  background-color: rgb(255, 255, 255);
  box-sizing: border-box;
  padding: 20px;
  font-family:Lomino, LominoSubset, "open-sans", sans-serif; 
  margin-bottom: 50px;
}

.industryDetailTitle{
  position: relative;
  float: left;
  width: 100%;
  text-align: center;
  font-size: 60px;
  font-weight: 600;
}

.industryDetailDescription{
  position: relative;
  float: left;
  width: 100%;
  text-align: center;
  font-size: 16px;
  font-weight: 300;
  margin-bottom: 30px;
}

.industryDetailImage{
  position: relative;
  float: left;
  width: 100%;
  height: auto;
}

.industryDetailImage img{
  position: relative;
  float: left;
  width: 100%;
  height: auto;
}

.industryWrapperNextImg{
  position: absolute;
  bottom: 0px;
  right: 0px;
  width: 100px;
  height: auto;
}

.industryWrapperNextImg img{
  width: 100%;
  height: auto;
}

.howItWorksVideo{
  width: 100%;
  height: auto;
  position: relative;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 5px 15px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;
  margin-bottom: 8px;
  color: rgb(76, 83, 93);
  border-radius: 4px;
  box-sizing: border-box;
  padding: 15px;
  background-color: white;
}

.featureRow{
  position: relative;
  float: left;
  width: 100%;
  height: auto;
  font-size: 15px;
  margin-bottom: 35px;
}

.keyFeature{
  position: relative;
  float: left;
  width: 33%;
  height: auto;
  text-align: left;
}

.featureDesc{
  position: relative;
  float: left;
  width: 66%;
  height: 100%;
}

.featureTitle{
  position: relative;
  float: left;
  width: 100%;
  height: auto;
  font-size: 18px;
  margin-bottom: 15px;
}

@media screen and (max-width : 750px) {
  .keyFeature{
    width: 100%;
    text-align: center;
    margin-bottom: 10px;
  }

  .pageDescription{
    text-align: center;
    width: 90%;
    margin-left: 5%;
  }

  .featureRow{
    text-align: center;
    margin-bottom: 0px;
  }

  .featureDesc{
    width: 100%;
  }

  .industryWrapper{
    width: 100%;
    margin-left: 0px;
    margin-right: 0px;
    height: 150px;
    cursor: pointer;
  }

  .industryWrapperTitle{
    font-size: 20px;
  }

  .industryDetailTitle{
    font-size: 35px;
  }
}



.teamWrapper{
  position: relative;
  float: left;
  width: 100%;
  height: auto;
  margin-bottom: 20px;
}

.teamMemberOdd{
  position: relative;
  float: left;
  width: 100%;
  height: auto;
  background-color: #ebebeb;
  box-sizing: border-box;
  padding: 10px;
  margin-bottom: 10px;
}

.teamMemberImage{
  position: relative;
  float: left;
  width: 20%;
  height: auto;
  box-sizing: border-box;
  padding: 5px;
}

.teamMemberImage img{
  position: relative;
  float: left;
  width: 100%;
  height: auto;
}

.teamMemberDesc{
  position: relative;
  float: left;
  width: 70%;
  height: auto;
  box-sizing: border-box;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 15px;
}

.headingNew{
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  --brownish-grey: #707070;
  --charcoal-grey: #3b4550;
  --white: #f3f3f3;
  --row1Height: minmax(33.34vh,33.334fr);
  --row2Height: minmax(66.64vh,66.67fr);
  line-height: 1.42857143;
  font-size: 28px;
  color: #707070;
  font-family: Raleway,sans-serif;
  box-sizing: border-box;
  text-align: center;
  visibility: visible;
  width: 100%;
  height: auto;
  float: left;
  margin-top: 15px;
}

.largeVideoWrapper{
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  --brownish-grey: #707070;
  --charcoal-grey: #3b4550;
  --white: #f3f3f3;
  --row1Height: minmax(33.34vh,33.334fr);
  --row2Height: minmax(66.64vh,66.67fr);
  line-height: 1.42857143;
  font-size: 1.6rem;
  color: #707070;
  font-family: Raleway,sans-serif;
  box-sizing: border-box;
  display: block;
}

.largeVideoPadding{
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    --brownish-grey: #707070;
    --charcoal-grey: #3b4550;
    --white: #f3f3f3;
    --row1Height: minmax(33.34vh,33.334fr);
    --row2Height: minmax(66.64vh,66.67fr);
    line-height: 1.42857143;
    font-size: 1.6rem;
    color: #707070;
    font-family: Raleway,sans-serif;
    box-sizing: border-box;
    padding-bottom: 6rem!important;
    /*padding-top: 9rem!important;*/
    background-color: #f3f3f3;
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    max-height: 720px;
}

.largeVideoSubWrapper{
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    --brownish-grey: #707070;
    --charcoal-grey: #3b4550;
    --white: #f3f3f3;
    --row1Height: minmax(33.34vh,33.334fr);
    --row2Height: minmax(66.64vh,66.67fr);
    line-height: 1.42857143;
    font-size: 1.6rem;
    color: #707070;
    font-family: Raleway,sans-serif;
    box-sizing: border-box;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    width: 1170px;
    perspective: none;
}

.largeVideoSubWrapper2{
      -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    --brownish-grey: #707070;
    --charcoal-grey: #3b4550;
    --white: #f3f3f3;
    --row1Height: minmax(33.34vh,33.334fr);
    --row2Height: minmax(66.64vh,66.67fr);
    line-height: 1.42857143;
    font-size: 1.6rem;
    color: #707070;
    font-family: Raleway,sans-serif;
    box-sizing: border-box;
    min-height: 1px;
    padding-left: 15px;
    float: left;
    width: 100%;
    display: block;
    overflow: hidden;
    height: 100%;
    /*position: absolute;*/
    top: 0;
    right: 0;
    padding-right: 0;
    opacity: 1;
    transform-origin: 50% 50%;
    transform: scale(1);
}

.videoWrapper{
      -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    --brownish-grey: #707070;
    --charcoal-grey: #3b4550;
    --white: #f3f3f3;
    --row1Height: minmax(33.34vh,33.334fr);
    --row2Height: minmax(66.64vh,66.67fr);
    line-height: 1.42857143;
    font-size: 1.6rem;
    color: #707070;
    font-family: Raleway,sans-serif;
    box-sizing: border-box;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
    float: left;
    width: 66.66666667%;
    left: 33.33333333%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    background-position: 100% 0;
}

.videoOverlay{
      -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    --brownish-grey: #707070;
    --charcoal-grey: #3b4550;
    --white: #f3f3f3;
    --row1Height: minmax(33.34vh,33.334fr);
    --row2Height: minmax(66.64vh,66.67fr);
    line-height: 1.42857143;
    font-size: 1.6rem;
    color: #707070;
    font-family: Raleway,sans-serif;
    box-sizing: border-box;
    position: absolute;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg,#f3f3f3 0,#f3f3f3 53%,hsla(0,0%,100%,0));
    z-index: 100;
    display: block;
    transform: translate(-20%);
}

.videoDiv{
      -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    --brownish-grey: #707070;
    --charcoal-grey: #3b4550;
    --white: #f3f3f3;
    --row1Height: minmax(33.34vh,33.334fr);
    --row2Height: minmax(66.64vh,66.67fr);
    line-height: 1.42857143;
    font-size: 1.6rem;
    color: #707070;
    font-family: Raleway,sans-serif;
    box-sizing: border-box;
}

.video-player{
      -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    --brownish-grey: #707070;
    --charcoal-grey: #3b4550;
    --white: #f3f3f3;
    --row1Height: minmax(33.34vh,33.334fr);
    --row2Height: minmax(66.64vh,66.67fr);
    line-height: 1.42857143;
    font-size: 1.6rem;
    color: #707070;
    font-family: Raleway,sans-serif;
    box-sizing: border-box;
    display: inline-block;
    vertical-align: baseline;
    z-index: -999;
    height: 100%;
    transform: translate(0);
}

.video-player video{
  width: 100%;
  height: auto;
}

.mainVideoWrapper{
  width: 100%;
  height: auto;
  max-height: 500px;
  position: relative;
  overflow: hidden;
}

.mainPageExplanationBody{
  position: relative;
  float: left;
  width: 100%;
  height: auto;
  font-size: 18px;
  text-align: center;
  margin-top: 15px;
}

.howitworksExplanation{
  position: relative;
  width: 100%;
  height: auto;
}

.howitworksIndustryWrapper{
  position: relative;
  float: left;
  width: 100%;
  height: auto;
  margin-bottom: 50px;
}

.industryWrapper{
  position: relative;
  float: left;
  width: 31.3%;
  height: auto;
  text-align: left;
  margin-left: 1%;
  margin-right: 1%;
  text-align: left;
  background-color: #fff;
  box-shadow: 0 5px 15px 0 rgba(0,0,0,.08), 0 1px 3px 0 rgba(0,0,0,.12);
  margin-bottom: 8px;
  color: #4c535d;
  border-radius: 4px;
  box-sizing: border-box;
  padding: 10px;
  cursor: pointer;
}

.industryWrapperImg{
  position: relative;
  float: left;
  width: 100%;
  height: auto;
}

.industryWrapperImg img{
  width: 100%;
  height: auto;
}

.heroContentAbout{
  position: absolute;
  top: 20%;
  left: 50px;
  color: #3b4550;
  font-size: 30px;
  font-family: Segoe UI,SegoeUI,"Helvetica Neue",Helvetica,Arial,sans-serif;
  /* background-color: rgb(0, 0, 0, 0.5); */
  box-sizing: border-box;
  padding: 10px;
  max-width: 50%;
  height: auto;
  max-height: 60%;
  text-align: left;
  line-height: 22px;
  z-index: 101;
  /* overflow: hidden;*/
  font-family: Raleway,sans-serif;
}

.heroContentHeader{
  font-size: 35px;
  line-height: 35px;
}

.heroContentBody{
  font-size: 18px;
  line-height: 18px;
  margin-bottom: 15px;
}

.trialButton{
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    --brownish-grey: #707070;
    --charcoal-grey: #3b4550;
    --white: #f3f3f3;
    --row1Height: minmax(33.34vh,33.334fr);
    --row2Height: minmax(66.64vh,66.67fr);
    font-family: Raleway;
    font-stretch: normal;
    font-style: normal;
    text-align: left;
    font-weight: 500;
    line-height: 1.08;
    letter-spacing: normal;
    box-sizing: border-box;
    color: #fff;
    --borderWidth: 2.5px;
    background-color: #eee;
    display: inline-block;
    border-width: var(--borderWidth);
    white-space: normal;
    position: relative;
    -webkit-backface-visibility: hidden;
    transition: box-shadow .4s cubic-bezier(0,0,.23,1);
    text-transform: uppercase;
    font-size: 16px;
    border-radius: 4px;
    box-shadow: 0 0 25.8px 3.2px rgba(0,0,0,.5);
    border: 2.5px solid #a23030;
    background-image: linear-gradient(90deg,#dc3545,#880909);
    outline: none;
    text-decoration: none;
    cursor: pointer;
    margin-left: 2rem;
    padding: 1rem 2rem;
    margin-bottom: 4px;
    margin-top: 4px;
}

.trialButton:hover{
    background-image: linear-gradient(90deg,#880909,#dc3545);
}

@media screen and (max-width : 750px) {
  .teamMemberImage{
    width: 100%;
    height: auto;
  }

  .teamWrapper{
    text-align: center;
  }

  .teamMemberDesc{
    width: 100%;
  }

  .pageDescription ul{
    text-align: left;
  }

  .heroContentAbout{
    width: 100%;
    max-width: none;
    left: 0px;
  }

  .heroContentHeader{
    font-size: 25px;
    line-height: 25px;
    width: 100%;
  }

  .heroContentBody{
    font-size: 12px;
    line-height: 12px;
    margin-bottom: 10px;
    width: 100%;
  }

  .trialButton{
    font-size: 12px;
  }

  .industryWrapper{
    width: 80%;
    margin-left: 10%;
  }
}

@media screen and (max-width : 450px) {
  .heroContentAbout{
    display: none;
  }

  .videoOverlay{
    display: none;
  }
}
.jobLink{
  color: #0313fc !important;
  text-decoration: underline !important;
}
/*.contactForm{
  position: relative;
  display: inline-block;
  width: 100%;
  height: auto;
  border-style: solid;
  border-width: 1px;
  border-color: #cbced3;
  margin: auto;
  text-align: left;
  margin-top: 15px;
  box-sizing: border-box;
  padding: 10px;
  color: #000000;
  background-color: #f2f2f2;
  margin-bottom: 10px;
}*/

.contactForm{
  position: relative;
  text-align: left;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 5px 15px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;
  margin-bottom: 8px;
  width: -webkit-min-content;
  width: -moz-min-content;
  width: min-content;
  min-width: 100%;
  color: rgb(76, 83, 93);
  border-radius: 4px;
  box-sizing: border-box;
  padding: 15px;
  height: auto;
}

.contactInput{
  position: relative;
  width: 100%;
  box-sizing: border-box;
  height: 35px;
  padding: 5px;
}

.contactArea{
  position: relative;
  width: 100%;
  max-width: 100%;
  min-width: 100%;
  min-height: 150px;
  height: auto;
  box-sizing: border-box;
  margin-bottom: 15px;
}

.recaptchaWrapper{
  position: relative;
  width: 100%;
  height: auto;
  margin-bottom: 10px;
}

.submitContactBtnWrapper{
  position: relative;
  width: 100%;
  height: auto;
}
.pageDescription a{
  	text-decoration: none;
  	color: #000;
}

.pageDescription a:hover{
 	text-decoration: none;
  	color: #000;
}

.newsArticleWrapper{
	position: relative;
	float: left;
	width: 80%;
	margin-left: 10%;
	box-sizing: border-box;
	padding: 10px;
	text-align: left;
}

.newsImageFull{
	position: relative;
    width: 80%;
    margin-left: 10%;
    height: auto;
}


.resetPasswordForm {
    position: relative;
    float: left;
    height: auto;
    width: 60%;
    margin-left: 20%;
}

.resetPasswordForm input {
    position: relative;
    width: 100%;
    height: 30px;
    margin-bottom: 20px;
    font-size: 20px;
}

.resetPasswordFormLabel{
	position: relative;
	width: 100%;
	float: left;
	height: auto;
	text-align: left;
}

.resetPasswordForm button {
    background-color: #c62828;
    border: none;
    color: #ffffff;
    height: 50px;
    position: relative;
    width: 100%;
    margin-bottom: 20px;
    font-size: 20px;
    cursor: pointer;
}
.StripeElement {
  margin: 15px auto;
  padding: 10px 12px;
  color: #32325d;
  background-color: whitesmoke;
  border: 1px solid transparent;
  border-radius: 4px;
  width: 100%;
  position: relative;
  float: left;
}

.stripeForm{
  position: relative;
  float: left;
  width: 100%;
  height: auto;
}

.stripeForm label{
  width: 100%;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}

.product {
  width: 100%;
  max-width: 450px;
  margin: auto;
  box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.4);
  border-radius: 10px;
  overflow: hidden;
}

.btn-pay {
  display: block;
  width: 100%;
  border: none;
  background-color: #007bff;
  color: #fff;
  padding: 10px;
  font-size: 18px;
  cursor: pointer;
}

.product-info {
  padding: 0 16px;
}

h3.product-title {
  font-size: 28px;
  margin-bottom: 15px;
}

h4.product-price {
  font-size: 24px;
  margin: 0;
  margin-bottom: 30px;
  color: #777;
  font-weight: 500;
}

.product-description{
  position: relative;
  width: 100%;
  height: auto;
  float: left;
  margin-bottom: 25px;
}

.subscriptionWrapper{
  text-align: left;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 5px 15px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;
  margin-bottom: 8px;
  margin-left: 2%;
  margin-right: 2%;
  width: 46%;
  color: rgb(76, 83, 93);
  border-radius: 4px;
  box-sizing: border-box;
  padding: 15px;
  float:left;
}

@-webkit-keyframes fade {
  from {
    opacity: 0;
    transform: scale3D(0.95, 0.95, 0.95);
  }
  to {
    opacity: 1;
    transform: scale3D(1, 1, 1);
  }
}

@keyframes fade {
  from {
    opacity: 0;
    transform: scale3D(0.95, 0.95, 0.95);
  }
  to {
    opacity: 1;
    transform: scale3D(1, 1, 1);
  }
}

.Form {
  -webkit-animation: fade 200ms ease-out;
          animation: fade 200ms ease-out;
}

.FormGroup {
  margin: 0 15px 20px;
  padding: 0;
  border-style: none;
  background-color: #f7f7f7;
  will-change: opacity, transform;
  /*box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08),
    inset 0 1px 0 #829fff;*/
  border-radius: 4px;
  color: #000000 !important;
}

.FormRow {
  display: flex;
  align-items: center;
  margin-left: 15px;
  /*border-top: 1px solid #819efc;*/
}

.FormRow:first-child {
  border-top: none;
}

.FormRow label{
  margin-bottom: 0px !important;
  width: 20px;
}

.FormRowLabel {
  width: 15%;
  min-width: 70px;
  padding: 11px 0;
  color: #000000;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@-webkit-keyframes void-animation-out {
  0%,
  to {
    opacity: 1;
  }
}

@keyframes void-animation-out {
  0%,
  to {
    opacity: 1;
  }
}
.FormRowInput:-webkit-autofill {
  -webkit-text-fill-color: #fce883;
  /* Hack to hide the default webkit autofill */
  -webkit-transition: background-color 100000000s;
  transition: background-color 100000000s;
  -webkit-animation: 1ms void-animation-out;
          animation: 1ms void-animation-out;
}

.FormRowInput {
  font-size: 16px;
  width: 100%;
  padding: 11px 15px 11px 5px;
  color: #000000;
  background-color: transparent;
  -webkit-animation: 1ms void-animation-out;
          animation: 1ms void-animation-out;
  border-color: #0000004a;
  border-top-color: rgba(0, 0, 0, 0.29);
  border-right-color: rgba(0, 0, 0, 0.29);
  border-bottom-color: rgba(0, 0, 0, 0.29);
  border-left-color: rgba(0, 0, 0, 0.29);
  border-width: 1px;
}

::-webkit-input-placeholder{
  color: #000000;
}

:-ms-input-placeholder{
  color: #000000;
}

::-ms-input-placeholder{
  color: #000000;
}

::placeholder{
  color: #000000;
}

.FormRowInput::-webkit-input-placeholder {
  color: #87bbfd;
}

.FormRowInput:-ms-input-placeholder {
  color: #87bbfd;
}

.FormRowInput::-ms-input-placeholder {
  color: #87bbfd;
}

.FormRowInput::placeholder {
  color: #87bbfd;
}

@media screen and (max-width : 750px) {
  .subscriptionWrapper{
    width: 100%;
  }
}
.solutionWrapper {
    position: relative;
    float: left;
    width: 45%;
    height: 350px;
    text-align: left;
    margin-left: 2%;
    margin-right: 2%;
    text-align: center;
    background-color: #fff;
    box-shadow: 0 5px 15px 0 rgba(0,0,0,.08), 0 1px 3px 0 rgba(0,0,0,.12);
    margin-top: 25px;
    color: #4c535d;
    border-radius: 4px;
    box-sizing: border-box;
    padding: 10px;
    cursor: pointer;
}

.solutionFullWrapper {
    position: relative;
    float: left;
    width: 94%;
    height: 350px;
    text-align: left;
    margin-left: 2%;
    margin-right: 2%;
    text-align: center;
    background-color: #fff;
    box-shadow: 0 5px 15px 0 rgba(0,0,0,.08), 0 1px 3px 0 rgba(0,0,0,.12);
    margin-top: 25px;
    color: #4c535d;
    border-radius: 4px;
    box-sizing: border-box;
    padding: 10px;
    cursor: pointer;
}

.solutionFullWrapperImage {
    position: relative;
    max-width: 250px;
    max-height: 200px;
    /* height: auto; */
    width: auto;
    float: left;
    box-sizing: border-box;
    padding: 10px;
}

.solutionFullWrapperImage img{
    position: relative;
    width: 100%;
    height: 100%;
}

.solutionWrapperImage {
    position: relative;
    width: 50%;
    max-width: 250px;
    height: auto;
    float: left;
    box-sizing: border-box;
    padding: 10px;
    max-height: 250px;
}

.solutionWrapperImage img{
    position: relative;
    width: 100%;
    height: 100%;
}

.solutionWrapperTitle {
    position: relative;
    width: 100%;
    height: 50px;
    text-align: left;
    font-size: 18px;
    font-weight: bold;
}

.solutionTextWrapper{
    position: relative;
    float: left;
    width: 50%;
    height: 80%;
}

.solutionDescription{
    position: relative;
    float: left;
    width: 100%;
    height: auto;
    font-size: 12px;
    text-align: left;
}

.solutionPageExplanation {
    position: relative;
    float: left;
    width: 80%;
    margin-left: 10%;
    height: auto;
    margin-top: 10px;
    margin-bottom: 30px;
    font-size: 28px;
    text-align: center;
    font-family: McKinsey Sans,Helvetica Neue,Calibri,Corbel,Helvetica,Roboto,Droid,sans-serif;
}

.downloadWrapper{
    position: relative;
    float: left;
    width: 100%;
    height: 20%;
    box-sizing: border-box;
    text-align: center;
}

.downloadWrapper img{
    position: relative;
    float: left;
    max-width: 50%;
    height: auto;
    max-height: 65px;
    display: block;
}

@media screen and (max-width : 750px) {
    .solutionFullWrapper {
        position: relative;
        float: left;
        width: 96%;
        height: auto;
        text-align: left;
        margin-left: 2%;
        margin-right: 2%;
        text-align: center;
        background-color: #fff;
        box-shadow: 0 5px 15px 0 rgba(0,0,0,.08), 0 1px 3px 0 rgba(0,0,0,.12);
        margin-top: 25px;
        color: #4c535d;
        border-radius: 4px;
        box-sizing: border-box;
        padding: 10px;
        cursor: pointer;
    }

    .solutionWrapper {
        position: relative;
        float: left;
        width: 96%;
        height: auto;
        text-align: left;
        margin-left: 2%;
        margin-right: 2%;
        text-align: center;
        background-color: #fff;
        box-shadow: 0 5px 15px 0 rgba(0,0,0,.08), 0 1px 3px 0 rgba(0,0,0,.12);
        margin-top: 25px;
        color: #4c535d;
        border-radius: 4px;
        box-sizing: border-box;
        padding: 10px;
        cursor: pointer;
    }

    .solutionFullWrapperImage {
        position: relative;
        width: 100%;
        max-width: none;
        max-height: none;
        height: auto;
        float: left;
        box-sizing: border-box;
        padding: 10px;
    }

    .solutionWrapperImage {
        position: relative;
        width: 100%;
        max-width: none;
        max-height: none;
        height: auto;
        float: left;
        box-sizing: border-box;
        padding: 10px;
    }

    .solutionTextWrapper{
        position: relative;
        float: left;
        width: 100%;
        height: auto;
    }

    .solutionDescription{
        position: relative;
        float: left;
        width: 100%;
        height: auto;
        font-size: 12px;
        text-align: left;
    }
}
.username {
  position: relative;
  float: left;
  width: 20%;
  bottom: 0px;
}

.contactId{
  position: relative;
  float: left;
  width: 15%;
  bottom: 0px;
  text-align: left;
}

.userLicense {
  position: relative;
  float: left;
  width: 20%;
  bottom: 0px;
}

.userRole {
  position: relative;
  float: left;
  width: 15%;
  bottom: 0px;
  text-align: left;
}

.userUpdate {
  position: relative;
  float: left;
  width: 30%;
  bottom: 0px;
  text-align: right;
}

.userInfoWrapper{
  position: relative;
  float: left;
  width: 100%;
  height: auto;
}

.userUpdate button{
  margin-left: 10px;
}

.userRolesWrapper{
  position: relative;
  float: left;
  width: 100%;
  height: auto;
}

.userRoleWrapper{
  position: relative;
  float: left;
  width: 100%;
  line-height: 30px;
}

.contactInfoWrapper{
  line-height: 30px !important;
}

.userCreateClose {
    position: relative;
    width: auto;
    height: 60px;
    font-size: 20px;
    line-height: 60px;
    float: right;
    cursor: pointer;
    z-index: 10;
    text-decoration: none;
}

.userCreateClose a {
    text-decoration: none!important;
    color: #000!important;
}

.whiteWrapperBody{
    width: 100%;
}

