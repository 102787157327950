.mainVideoWrapper{
  width: 100%;
  height: auto;
  position: relative;
  overflow: hidden;
}

.mainVideoWrapper img{
  width: 100%;
  height: auto;
  position: relative;
}

.heroContent{
  position: absolute;
  top: 40%;
  left: 50px;
  color: #fff;
  font-size: 30px;
  font-family: Segoe UI,SegoeUI,"Helvetica Neue",Helvetica,Arial,sans-serif;
  background-color: rgb(0, 0, 0, 0.5);
  box-sizing: border-box;
  padding: 10px;
  max-width: 50%;
  height: auto;
  max-height: 60%;
  text-align: left;
  line-height: 22px;
  z-index: 100;
  overflow: hidden;
}

.learnMoreBtn{
  background-color: #c62828;
  border: none;
  color: #ffffff;
  height: 50px;
  position: relative;
  max-width: 400px;
  width: 100%;
  margin-bottom: 20px;
  font-size: 20px;
  cursor: pointer;
}

.heroButton{
  box-sizing: border-box;
  padding: 10px;
  font-size: 18px;
  background-color: #c62828;
  color: #ffffff;
  border-style: none;
  cursor: pointer;
}

.mainVideoWrapper video{
  object-fit: cover;
  width: 100%;
  height: auto;
}

.homePageExplanation{
  position: relative;
  float: left;
  width: 80%;
  margin-left: 10%;
  height: auto;
  margin-top: 20px;
  margin-bottom: 30px;
  text-align: center;
  font-size: 18px;
}

.mainPageSteps{
  z-index: 2;
  position: relative;
  float: left;
  width: 80%;
  height: auto;
  text-align: center;
  margin-left: 10%;
  margin-top: 25px;
}

.mainPageStep{
  z-index: 2;
  position: relative;
  float: left;
  width: 33.33%;
  height: auto;
  box-sizing: border-box;
  padding: 5px;
  border-left-style: solid;
  border-left-width: 1px;
  border-color: #bbbbbb;
}

.mainPageStep1{
  z-index: 2;
  position: relative;
  float: left;
  width: 33.33%;
  height: auto;
  box-sizing: border-box;
  padding: 5px;
  border-left-style: solid;
  border-left-width: 1px;
  border-color: #bbbbbb;
}

.mainPageStep3{
  z-index: 2;
  position: relative;
  float: left;
  width: 33.33%;
  height: auto;
  box-sizing: border-box;
  padding: 5px;
  border-right-style: solid;
  border-right-width: 1px;
  border-color: #bbbbbb;
  border-left-style: solid;
  border-left-width: 1px;
}

.mainPageNewsWrapper{
  position: relative;
  float: left;
  width: 80%;
  min-height: 80px;
  height: auto;
  margin-left: 10%;
  margin-top: 50px;
  text-align: left;
  font-size: 30px;
  color: #c62828;
}

.mainPageExplanation{
  position: relative;
  float: left;
  width: 80%;
  margin-left: 10%;
  height: auto;
  margin-top: 10px;
  margin-bottom: 50px;
}

.newsClipWrapper{
  position: relative;
  float: left;
  width: 100%;
  height: auto;
  text-align: left;
  text-align: center;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 5px 15px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;
  margin-bottom: 8px;
  color: rgb(76, 83, 93);
  border-radius: 4px;
  box-sizing: border-box;
  padding: 15px;
}

.newsClipWrapper a{
  text-decoration: none;
  color: rgb(76, 83, 93);
}

.footerSpace{
  position: relative;
  float: left;
  height: 200px;
  width: 100%;
}

.newsImage{
  position: relative;
  float: left;
  width: 20%;
  height: auto;
  box-sizing: border-box;
  padding: 5px;
}

.newsImage img{
  position: relative;
  float: left;
  width: 100%;
  height: auto;
}

.newsContent{
  position: relative;
  float: left;
  width: 80%;
  height: auto;
  box-sizing: border-box;
  padding: 5px;
}

.newsTitle{
  position: relative;
  float: left;
  width: 100%;
  font-size: 25px;
  text-align: left;
  font-size: 28px;
}

.newsDate{
  position: relative;
  float: left;
  width: 100%;
  font-size: 25px;
  text-align: left;
  font-size: 14px;
  margin-bottom: 20px;
}

.newsText{
  position: relative;
  float: left;
  width: 100%;
  margin-top: 10px;
  font-size: 16px;
  text-align: left;
}

.homeBody {
  position: relative;
  float: left;
  width: 90%;
  margin-left: 5%;
  height: auto;
  margin-top: 50px;
  margin-bottom: 50px;
}

.homeBodyHeading{
  font-size: 36px;
  line-height: 36px;
  font-family: 'Open Sans';
  font-weight: bolder;
  margin-bottom: 20px;
}

.homeBodyMain{
  position: relative;
  float: left;
  width: 100%;
  height: auto;
}

.homeBodyMainLeft{
  position: relative;
  float: left;
  width: 30%;
  height: auto;
  box-sizing: border-box;
  padding: 15px;
}

.homeBodyMainLeft img{
  position: relative;
  float: left;
  width: 100%;
  height: auto;
}

.homeBodyMainRight{
  position: relative;
  float: left;
  width: 70%;
  height: auto;
  text-align: left;
  box-sizing: border-box;
  padding: 15px;
}

.imageOverlay{
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgb(255,255,255);
  background: radial-gradient(circle, rgba(240,242,245,0) 20%, rgba(240,242,245,1) 100%);
  z-index: 100;
  left: 0px;
  top: 0px;
}

@media screen and (max-width : 750px) {
  .mainPageStep{
    width: 100%;
    border-left: none;
    border-right: none;
    border-bottom: 2px solid #bbbbbb;
    margin-bottom: 10px;
  }

  .mainPageStep1{
    width: 100%;
    border-left: none;
    border-right: none;
    border-bottom: 2px solid #bbbbbb;
    margin-bottom: 10px;
  }

  .mainPageStep3{
    width: 100%;
    border-left: none;
    border-right: none;
    border-bottom: 2px solid #bbbbbb;
    margin-bottom: 10px;
  }

  .mainPageNewsWrapper{
    width: 100%;
    margin-left: 0px;
    box-sizing: border-box;
    padding: 10px;
    text-align: center;
  }

  .newsContent{
    width: 100%;
  }

  .newsTitle{
    text-align: center;
  }

  .newsClipWrapper{
    text-align: center;
    height: auto;
  }

  .newsImage{
    position: relative;
    display: block;
    width: 100%;
    max-height: 220px;
    height: auto;
    margin-top: 15px;
    text-align: center;
  }

  .newsImage img{
    max-width: 100%;
    width: auto;
    height: auto;
    max-height: 220px;
    float: none;
  }

  .newsDate{
    text-align: center;
    margin-bottom: 20px;
  }

  .newsText{
    text-align: center;
  }

  .homeBodyMainLeft{
    width: 100%;
  }

  .homeBodyMainRight{
    width: 100%;
  }
}

@media screen and (max-width : 790px) {
  .heroContent{
    display: none;
  }
}