a{
  text-decoration: none;
}

.noProjects{
  position: relative;
  float: left;
  width: 80%;
  margin-left: 10%;
  height: auto;
  color: #000;
  box-sizing: border-box;
  padding: 15px;
  margin-top: 20px;
  box-shadow:  0 0 10px  rgba(0,0,0,0.6);
  -moz-box-shadow: 0 0 10px  rgba(0,0,0,0.6);
  -webkit-box-shadow: 0 0 10px  rgba(0,0,0,0.6);
  -o-box-shadow: 0 0 10px  rgba(0,0,0,0.6);
  border-radius: 15px;
  background-color: #fff3cd;
  border-color: #ffeeba;
}

.projectsFilterWrapper{ 
  position: relative;
  width: 100%;
  height: 20px;
  margin-top: 30px;
  color: #6d6d6d;
  text-align: left;
  box-sizing: border-box;
  padding: 5px;
  line-height: 20px;
  font-size: 20px;
}

.projectsFilterWrapper img{
    position: relative;
    height: 20px;
    line-height: 20px;
    width: auto;
    display: inline-block;
}

.filterId{
  position: relative;
    float: left;
    width: 10%;
    bottom: 0px;
    cursor: pointer;
}

.filterCallMadeBy{
    position: relative;
    float: left;
    width: 22%;
    bottom: 0px;
    text-align: right;
    cursor: pointer;
}

.filterName{
  position: relative;
    float: left;
    width: 22%;
    bottom: 0px;
    text-align: right;
    cursor: pointer;
}

.filterDuration{
  position: relative;
    float: left;
    width: 22%;
    bottom: 0px;
    text-align: right;
    cursor: pointer;
}

.filterDate{
  position: relative;
    float: left;
    width: 22%;
    bottom: 0px;
    text-align: right;
    cursor: pointer;
}

.projectsFilterEnd{
  position: relative;
    float: left;
    width: 15%;
    bottom: 0px;
    cursor: pointer;
}

.projectsFilterResponses{
  position: relative;
    float: left;
    width: 15%;
    bottom: 0px;
    cursor: pointer;
    text-align: right;
}

@media screen and (max-width : 750px) {
  .projectsFilterWrapper{
    font-size: 12px;
  }
}