.headerSmall{
  position: relative;
  float: left;
  width: 100%;
  height: 70px;
  text-align: center;
  font-size: 50px;
  color: #ffffff;
  z-index: 102;
  background-color: #2b2b2b;
  line-height: 70px;
  box-shadow: 0px 2px 15px rgb(0 0 0 / 50%);
}

.headerSmall a{
  text-decoration: none;
  color: #fff;
}

.logoSmall{
  position: relative;
  float: left;
  width: auto;
  height: 50px;
  z-index: 10;
  background-repeat: none;
  margin-top: 10px;
  margin-left: 5px;
  line-height: 50px;
  color: #ffffff;
  font-family: 'BebasNeueRegular', sans-serif;
  font-style: normal;
}

.logoSmall img{
  position: relative;
  float: left;
  width: auto;
  height: 100%;
  z-index: 10;
}

.mainMenu{
  position: absolute;
  top: 25px;
  right: 25px;
  width: auto;
  height: auto;
  z-index: 110 !important;
  color: #000000;
}

.menuOption{
  position: relative;
  float: left;
  width: auto;
  height: auto;
  z-index: 2;
  font-size: 18px;
  line-height: 45px;
  padding-left: 20px;
  padding-right: 20px;
  text-decoration: none;
}

.menuRegister{
  position: relative;
  float: left;
  width: 175px;
  height: 40px;
  z-index: 2;
  font-size: 18px;
  line-height: 45px;
  padding-left: 5px;
  padding-right: 5px;
  text-decoration: none;
  cursor: pointer;
}

.menuOption:hover{
  background-color: #454545;
}

.headerActive{
  border-bottom-style: solid;
  border-bottom-width: 3px;
  border-bottom-color: #c62828;
}

.mobileMenu{
  display: none;
}

.mobileMenuWrapper{
  display: none;
}

.subMenu{
  display: none;
  position: absolute;
  width: auto;
  background-color: #454545;
  left: 0px;
  min-width: 250px;
  text-align: left;
  box-sizing: border-box;
  padding: 5px;
  z-index: 102 !important;
}

.subMenuOption{
  position: relative;
  float: left;
  width: 100%;
  height: auto;
  box-sizing: border-box;
  padding: 5px;
}

.subMenuOption:hover{
  background-color: #2b2b2b;
}

.menuOption:hover .subMenu{
  display: block;
}

.registerButton{
  position: relative;
  float: left;
  height: 40px;
  width: 100%;
  box-sizing: border-box;
  /* padding: 5px; */
  background-color: #c62828;
  border-radius: 5px;
}
@media screen and (max-width : 750px) {
  .mainMenu{
    display: none;
  }

  .mobileMenu{
    display: block;
    background-image: url(/images/menu.png);
    position:absolute;
    width:40px;
    height:40px;
    top: 5px;
    right: 5px;
    margin-right: 10px;
    margin-top: 10px;
    z-index:101;
    background-repeat:no-repeat;
    background-size: 100%;
  }

  .mobileMenuWrapper{
    display: block;
    width: 100%;
    margin-top: 70px;
    height: auto;
    background-color: #2b2b2b;
    box-sizing: border-box;
    padding: 10px;
    text-align: center;
    line-height: 40px;
  }

  .mobileMenuOption{
    width: 100%;
    height: auto;
    font-size: 18px;
    border-bottom: 1px solid #595959;
  }
} 