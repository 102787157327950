.leftRail{
  position: fixed;
  margin-top: 50px;
  width: 50px;
  height: 100%;
  background-color: #2b2b2b;
  z-index: 100;
  top: 0px;
  left: 0px;
}

.fullLeftRail{
  position: fixed;
  margin-top: 50px;
  width: 250px;
  height: 100%;
  background-color: #2b2b2b;
  z-index: 100;
  top: 0px;
  left: 0px;
}

.leftRailMenu {
	position: absolute;
	float: left;
	width: 100%;
	height: 100%;
	margin-top: 20px;
}

.leftRailMenu img {
    position: relative;
    float: left;
    width: 25px;
    height: auto;
    margin-left: 12px;
}

.menuOptionText {
    position: relative;
    width: 200px;
    float: left;
    margin-left: 10px;
    text-align: left;
    color: #ffffff;
}

.menuOptionText a{
	text-decoration: none;
	color: #ffffff;
}

.menuImageWrapper{
	position: relative;
	width: 100%;
	height: 50px;
}

.active{
	border-left: 2px;
	border-left-color: #c62828;
    border-left-style: solid;
    height: 75px;
    position:relative;
    float:left;
}

.childActiveSmall {
    float: left;
    width: 50px;
    height: 25px;
    margin-top: 20px;
    margin-bottom: 20px;
}

.childActiveFull{
	float: left;
	width: 250px;
	height: 25px;
	margin-top: 20px;
	margin-bottom: 20px;
}

.logoutLeftMenuSmall {
    position: fixed;
    bottom: 20px;
    left: 0px;
    width: 50px;
    height: 25px;
    margin-top: 20px;
    cursor: pointer;
}

.logoutLeftMenuFull {
    position: fixed;
    bottom: 20px;
    left: 0px;
    width: 250px;
    height: 25px;
    margin-top: 20px;
    cursor: pointer;
}

.popover{
  position: absolute;
  top: 270px;
  left: 50px;
  margin: 0px;
  z-index: 1060;
  width: 300px;
  max-width: none !important; 
  font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,Liberation Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: .875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0,0,0,.2);
  border-radius: .3rem;
}

.arrow{
  margin: 0px;
  position: absolute;
  top: 0px;
  transform: translate(0px, 39.2px);
  left: calc(-.5rem - 1px);
  width: .5rem;
  height: 1rem;
  display: block;
}

.popover-header {
  padding: .5rem .75rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(.3rem - 1px);
  border-top-right-radius: calc(.3rem - 1px);
  text-decoration: none;
}

.popover-header a{
  color: #000;
}

.popover a{
  color: #000;
}

.closeBillingPopup{
  position: absolute;
  height: 20px;
  width: 20px;
  right: 5px;
  top: 5px;
  cursor: pointer;
}

.closeBillingPopup img{
  position: absolute;
  width: 100%;
  height: 100%;
}